const zones = [
  {
    label: 'Floor North',
  },
  {
    label: 'Floor South',
  },
  {
    label: 'Floor East',
  },
  {
    label: 'Floor West',
  },
  {
    label: 'Upper Bowl 201',
  },
  {
    label: 'Upper Bowl 202',
  },
  {
    label: 'Upper Bowl 203',
  },
  {
    label: 'Upper Bowl 204',
  },
  {
    label: 'Upper Bowl 205',
  },
  {
    label: 'Upper Bowl 206',
  },
  {
    label: 'Upper Bowl 207',
  },
  {
    label: 'Upper Bowl 208',
  },
  {
    label: 'Upper Bowl 209',
  },
  {
    label: 'Upper Bowl 210',
  },
  {
    label: 'Upper Bowl 211',
  },
  {
    label: 'Upper Bowl 212',
  },
  {
    label: 'Upper Bowl 213',
  },
  {
    label: 'Upper Bowl 214',
  },
  {
    label: 'Upper Bowl 215',
  },
  {
    label: 'Upper Bowl 216',
  },
  {
    label: 'Upper Bowl 217',
  },
  {
    label: 'Upper Bowl 218',
  },
  {
    label: 'Upper Bowl 219',
  },
  {
    label: 'Upper Bowl 220',
  },
  {
    label: 'Upper Bowl 221',
  },
  {
    label: 'Upper Bowl 222',
  },
  {
    label: 'Upper Bowl 223',
  },
  {
    label: 'Upper Bowl 224',
  },
  {
    label: 'Upper Bowl 225',
  },
  {
    label: 'Upper Bowl 226',
  },
  {
    label: 'Upper Bowl 230',
  },
  {
    label: 'Upper Bowl 231',
  },
  {
    label: 'Upper Bowl 232',
  },
  {
    label: 'Upper Bowl 233',
  },
  {
    label: 'Upper Bowl 234',
  },
  {
    label: 'Loge 1',
  },
  {
    label: 'Loge 2',
  },
  {
    label: 'Loge 3',
  },
  {
    label: 'Loge 4',
  },
  {
    label: 'Loge 5',
  },
  {
    label: 'Loge 6',
  },
  {
    label: 'Loge 7',
  },
  {
    label: 'Loge 8',
  },
  {
    label: 'Loge 9',
  },
  {
    label: 'Loge 10',
  },
  {
    label: 'Loge 11',
  },
  {
    label: 'Loge 12',
  },
  {
    label: 'Loge 13',
  },
  {
    label: 'Loge 14',
  },
  {
    label: 'Loge 15',
  },
  {
    label: 'Loge 16',
  },
  {
    label: 'Loge 17',
  },
  {
    label: 'Loge 18',
  },
  {
    label: 'Loge 19',
  },
  {
    label: 'Loge 20',
  },
  {
    label: 'Loge 21',
  },
  {
    label: 'Loge 22',
  },
  {
    label: 'Loge 23',
  },
  {
    label: 'Loge 24',
  },
  {
    label: 'Loge 25',
  },
  {
    label: 'Loge 26',
  },
  {
    label: 'Loge 27',
  },
  {
    label: 'Loge 28',
  },
  {
    label: 'Loge 29',
  },
  {
    label: 'Loge 30',
  },
  {
    label: 'Loge 31',
  },
  {
    label: 'Lower Club 101',
  },
  {
    label: 'Lower Club 102',
  },
  {
    label: 'Lower Club 103',
  },
  {
    label: 'Lower Club 104',
  },
  {
    label: 'Lower Bowl 105',
  },
  {
    label: 'Lower Bowl 107',
  },
  {
    label: 'Lower Bowl 109',
  },
  {
    label: 'Lower Bowl 110',
  },
  {
    label: 'Lower Bowl 112',
  },
  {
    label: 'Lower Bowl 113',
  },
  {
    label: 'Lower Bowl 115',
  },
  {
    label: 'Lower Bowl 117',
  },
  {
    label: 'Lower Club 118',
  },
  {
    label: 'Lower Club 119',
  },
  {
    label: 'Lower Club 120',
  },
  {
    label: 'Lower Club 121',
  },
  {
    label: 'Lower Bowl 122',
  },
  {
    label: 'Lower Bowl 124',
  },
  {
    label: 'Lower Bowl 126',
  },
  {
    label: 'Lower Bowl 127',
  },
  {
    label: 'Lower Bowl 129',
  },
  {
    label: 'Lower Bowl 130',
  },
  {
    label: 'Lower Bowl 132',
  },
  {
    label: 'Lower Bowl 134',
  },
  {
    label: 'Sky Lounge AA',
  },
  {
    label: 'Sky Lounge BB',
  },
  {
    label: 'Sky Lounge CC',
  },
  {
    label: 'Sky Lounge DD',
  },
  {
    label: 'Sky Lounge EE',
  },
  {
    label: 'Sky Lounge A',
  },
  {
    label: 'Sky Lounge B',
  },
  {
    label: 'Sky Lounge C',
  },
  {
    label: 'Sky Lounge D',
  },
  {
    label: 'Sky Lounge E',
  },
  {
    label: 'Sportsnet Club AA',
  },
  {
    label: 'Sportsnet Club BB',
  },
  {
    label: 'Sportsnet Club CC',
  },
  {
    label: 'Sportsnet Club DD',
  },
  {
    label: 'Sportsnet Club EE',
  },
  {
    label: 'Sportsnet Club A',
  },
  {
    label: 'Sportsnet Club B',
  },
  {
    label: 'Sportsnet Club C',
  },
  {
    label: 'Sportsnet Club D',
  },
  {
    label: 'Sportsnet Club E',
  },
];

export default zones;
