import {
  convertPriceToCAD,
  convertPriceToCADNoFee,
  excludeCheck,
  formatJaysTEZoneToFF,
  formatTEZoneCFLToFF,
  formatTEZoneToFF,
  formatTEZoneMLSToFF,
  generalTENHLTeams,
  getRowSection,
  getSectionZone,
  leafsRaptorsToIncludeclude,
  teSenatorRowCheck,
} from "./seat-helpers";
import { API } from "aws-amplify";
import { getTeamTier } from "./get-team-tier";
import moment from "moment-timezone";

export const storeLowestPrice = async (gameId, seatPrice) => {
  const data = await API.post("v2", "marketplace/ticketEvo/saveLowestPrice", {
    body: {
      gameId: gameId,
      price: Number(seatPrice),
    },
  });
};

export const formatTEToExclude = (seat, game) => {
  const excludeFormat = ["eticket"];
  const excludeNotes = ["tm mobile mobile xfer links", "xfer links"];
  const includeOnly = ["public xfer", "xfer"];
  const jaysIncludeOnly = ["mobile transfer", "public xfer", "xfer", "xfer\\nmlbxfer"];
  const findExclude = excludeFormat.find(
    (format) => String(seat.format).toLowerCase() === format
  );
  const findExcludeNotes = excludeNotes.find(
    (note) => String(seat.public_notes).toLowerCase().includes(note)
  );
  const findWallet = String(seat.public_notes).toLowerCase().search("wallet");
  
  // exclude in hand ticket
  const shouldBeInHandOnly = (["calgary-flames", "winnipeg-jets"].includes(game.homeTeamSlug) && !game.isSpecial);
  const inHandOnly = shouldBeInHandOnly && !seat.in_hand;

  //exclude 317 senator and ROW SD
  let sensSeat = ["314", "315", "316", "317"];
  const sensSlug = ["ottawa-senators"].includes(game.homeTeamSlug);
  const excludeSensSection = (sensSlug && (sensSeat.indexOf(seat.section) > -1)) || (sensSlug && seat.row.toLowerCase().replace(/\s/g, '').includes('sd')) || (sensSlug && seat.row.toLowerCase().replace(/\s/g, '').includes('stn'));

  //exclude 139 bluejays
  const tbjSlug = ["toronto-blue-jays"].includes(game.homeTeamSlug);
  const excludetbjSection = (tbjSlug) && (seat.section == '139B') && (seat.row == '3');
  const includetbjNotes = (tbjSlug) && (!jaysIncludeOnly.includes(String(seat.public_notes).toLowerCase()));

  //include public xfer only canucks, jays & grey cup
  const includeTeam = (["vancouver-canucks", "grey-cup"].includes(game.homeTeamSlug) && !game.isSpecial);
  const includeNotes = (includeTeam) && (!includeOnly.includes(String(seat.public_notes).toLowerCase()));

  //exclude no alcohol to abbotsford
  const abbotsfordExclude = (game.homeTeamSlug === "abbotsford-canucks") && (String(seat.public_notes).toLowerCase()).includes("no alcohol");
  
  return abbotsfordExclude || includetbjNotes || includeNotes || excludeSensSection || excludetbjSection || findExclude || findExcludeNotes || inHandOnly || findWallet > -1 ? true : false;
};

export const getGridNHLGeneral = (
    ticket_groups,
    lowestParams,
    currencyRate,
    selectedTeam,
    game
  ) => {
  const slicedListing = ticket_groups.filter(
    (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
  );
  let seatList = [];
  if (slicedListing.length > 0) {
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const convertedPrice = game.homeTeamSlug === "winnipeg-jets" ? convertPriceToCAD(seat, currencyRate, game) : convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, selectedTeam),
        row: getRowSection(seat.row, selectedTeam),
        noOfSeats: Number(seat.available_quantity),
        tier: getTeamTier(selectedTeam, zoneInvetory),
        quantityOptions: [Number(seat.available_quantity)],
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        selectedTeam === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        seatList.push(seatItem);
      if (seatList.length > 0) {
        break;
      }
    }
  }
  return seatList;
}
export const getGridJets = (ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game) => {
  const seatList = [];
  const upGrid = getGridNHLGeneral(ticket_groups,
    lowestParams,
    currencyRate,
    selectedTeam,
    game);
  let seatItem = {};
  seatItem['up'] = upGrid.length > 0 ? upGrid[0] : {};
  seatList.push(seatItem);
  const slicedListing = ticket_groups.filter(
    (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
  );
  const lowestJetsGeneral = generalTENHLTeams(game, slicedListing, currencyRate);
  const midZoneLowest = lowestJetsGeneral.seatsMerged.find((seat) => {
    return String(seat.zone).toLowerCase() === "middle zone";
  });
  if (midZoneLowest) {
    seatItem['mid'] = midZoneLowest;
    seatList.push(seatItem);
  }
  
  if (slicedListing.length > 0) {
    let innerSeat = [];
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const zoneNo = getSectionZone(seat.section, selectedTeam);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const lowZoneCheck = zoneInvetory.includes("Lower Zone");
      const zonesType = {
        low: lowZoneCheck,
      }
      const findMore3Option = seat.splits.find(
        (split) => split > 2
      )
        ? true
        : false;
      const lowerZoneList = [];
      for (let index = 101; index < 128; index++) {
        lowerZoneList.push(index);
      }
      const isSectionInclude = lowerZoneList.find((zone) => String(zone) === String(zoneNo)) ? true : false;
      const needMidCheck = isSectionInclude && findMore3Option;
      if (zonesType["low"] && needMidCheck) {
        const seatItem = {
          price: Number(convertedPrice),
          zone: zoneInvetory,
          zoneNo: getSectionZone(seat.section, selectedTeam),
          row: getRowSection(seat.row, selectedTeam),
          noOfSeats: Number(seat.available_quantity),
          tier: getTeamTier(selectedTeam, zoneInvetory),
          quantityOptions: [Number(seat.available_quantity)],
        };
        const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
        const isZoneExist = seatItem.zone !== "";
        const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
        const seatData = {};
        seatData['low'] = seatItem;
        !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          seatList.push(seatData);
        !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          innerSeat.push(seatData);
        if (innerSeat.length > 0) {
          break;
        }
      }
    }
  }
  return seatList;
}
export const getGridSens = (
    ticket_groups,
    lowestParams,
    currencyRate,
    selectedTeam,
    game
  ) => {
  const slicedListing = ticket_groups.filter(
    (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
  );
  let seatList = [];
  const sections = ["up", "mid", "low"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
        const zoneNo = getSectionZone(seat.section, selectedTeam);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Middle Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        const findMore3Option = seat.splits.find(
          (split) => split >= 2
        )
          ? true
          : false;
        const isSectionInclude = [206, 207, 208, 209 ,210, 220, 221, 222, 223, 224].find((zone) => zone === zoneNo) ? true : false;
        const needMidCheck = midZoneCheck ? (isSectionInclude && findMore3Option) : true;
        if (zonesType[sectI] && needMidCheck) {
          const seatItem = {
            price: seatList.length > 0 ? Number(convertedPrice) : Number(convertedPriceNoFee),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
          const isZoneExist = seatItem.zone !== "";
          const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
          const seatData = {};
          seatData[sectI] = seatItem;
          !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            seatList.push(seatData);
          !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  return seatList;
}
export const getGridChanuck = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
const slicedListing = ticket_groups.filter(
  (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
);
let seatList = [];
const sections = ["up", "mid", "low"];
sections.forEach((sectI) => {
  if (slicedListing.length > 0) {
    let innerSeat = [];
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const zoneNo = getSectionZone(seat.section, selectedTeam);
      const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const lowZoneCheck =  zoneInvetory.includes("Club Lower");
      const midZoneCheck = zoneInvetory.includes("Lower Bowl")
      const upZoneCheck = zoneInvetory.includes("Upper Bowl") || zoneInvetory.includes("Upper Bowl - Balcony");
      
      const zonesType = {
        low: lowZoneCheck,
        mid: midZoneCheck,
        up: upZoneCheck,
      };
      
      const excludeZone = [101, 102];

      const isSectionInclude = excludeZone.find((sec) => {
        return String(sec) === String(zoneNo);
      });
      
      if (zonesType[sectI] && !isSectionInclude) {
        const seatItem = {
          price: seatList.length > 0 ? Number(convertedPrice) : Number(convertedPriceNoFee),
          zone: zoneInvetory,
          zoneNo: getSectionZone(seat.section, selectedTeam),
          row: getRowSection(seat.row, selectedTeam),
          noOfSeats: Number(seat.available_quantity),
          tier: getTeamTier(selectedTeam, zoneInvetory),
          quantityOptions: [Number(seat.available_quantity)],
        };
        const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
        const isRowNumber =
          selectedTeam === "ottawa-senators"
            ? teSenatorRowCheck(seatItem.row)
            : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = seatItem.zone !== "";
        const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
        const seatData = {};
        seatData[sectI] = seatItem;
        !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          seatList.push(seatData);
        !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          innerSeat.push(seatData);
        if (innerSeat.length > 0) {
          break;
        }
        
      }
    }
  }
});
return seatList;
}
export const getGridMoose = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
const slicedListing = ticket_groups.filter(
  (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
);
let seatList = [];
const sections = ["low", "mid", "up"];
sections.forEach((sectI) => {
  if (slicedListing.length > 0) {
    let innerSeat = [];
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const convertedPrice = game.homeTeamSlug === "winnipeg-jets" ? convertPriceToCAD(seat, currencyRate, game) : convertPriceToCADNoFee(seat, currencyRate, game);
      const convertedPriceFee = convertPriceToCAD(seat, currencyRate, game);
      const lowZoneCheck = zoneInvetory.includes("Lower Zone");
      const midZoneCheck = zoneInvetory.includes("Middle Zone");
      const upZoneCheck = zoneInvetory.includes("Upper Zone");
      const zonesType = {
        low: lowZoneCheck,
        mid: midZoneCheck,
        up: upZoneCheck,
      }
      
      if (zonesType[sectI]) {
        const seatItem = {
          price: seatList.length > 0 ? Number(convertedPriceFee) : Number(convertedPrice),
          zone: zoneInvetory,
          zoneNo: getSectionZone(seat.section, selectedTeam),
          row: getRowSection(seat.row, selectedTeam),
          noOfSeats: Number(seat.available_quantity),
          tier: getTeamTier(selectedTeam, zoneInvetory),
          quantityOptions: [Number(seat.available_quantity)],
        };
        const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
        const isRowNumber =
          selectedTeam === "ottawa-senators"
            ? teSenatorRowCheck(seatItem.row)
            : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
        const isZoneExist = seatItem.zone !== "";
        const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
        const seatData = {};
        seatData[sectI] = seatItem;
        !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          seatList.push(seatData);
        
          !isExcludedSeat &&
          isZoneExist &&
          isZoneNumber &&
          isRowNumber &&
          innerSeat.push(seatData);
        if (innerSeat.length > 0) {
          break;
        }
      }
    }
  }
});
return seatList;
}
export const getGridFlames = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  const slicedListing = ticket_groups.filter(
    (seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game)
  );
  let seatList = [];
  const sections = ["low"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
        const zoneNo = getSectionZone(seat.section, selectedTeam);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck =
          zoneInvetory.includes("Lower Zone") ||
          zoneInvetory.includes("Press Level") ||
          zoneInvetory.includes("Lower Bowl") ||
          zoneInvetory.includes("Lower Club");
        const zonesType = {
          low: lowZoneCheck,
        };
        const findMore3Option = seat.splits.find(
          (split) => split > 2
        )
          ? true
          : false;
        const sectionData = [];
        for (let index = 101; index < 123; index++) {
          sectionData.push(index);
        }
        const isSectionInclude = sectionData.find((zone) => String(zone) === String(zoneNo)) ? true : false;
        const needLowCheck = isSectionInclude && findMore3Option;
        if (zonesType[sectI] && needLowCheck) {
          const seatItem = {
            price: Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const isZoneNumber = zoneInvetory !== "";
          const isZoneExist = true;
          const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
          const seatData = {};
          seatData[sectI] = seatItem;
          !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            seatList.push(seatData);
          !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  return seatList;
}
export const grabLowestFromTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  switch (game.homeTeamSlug) {
    case "calgary-flames": {
      return getGridFlames(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
    case "ottawa-senators": {
      return getGridSens(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
    case "vancouver-canucks": {
      return getGridChanuck(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
    case "winnipeg-jets": {
      return getGridJets(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
    case "manitoba-moose": {
      return getGridMoose(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "vancouver-canucks": {
      return getGridChanuck(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
  
    default: {
      return getGridNHLGeneral(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
  }
  
};

export const grabLowestSatmpeders = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  if (slicedListing.length > 0) {
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
      const convertedPrice = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, selectedTeam),
        row: getRowSection(seat.row, selectedTeam),
        noOfSeats: Number(seat.available_quantity),
        tier: getTeamTier(selectedTeam, zoneInvetory),
        quantityOptions: [Number(seat.available_quantity)],
      };
      const isZoneExist = seatItem.zone !== "";
       isZoneExist && seatList.push(seatItem);
      if (seatList.length > 0) {
        break;
      }
    }
  }
  return seatList;
}

export const grabLowestElks = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams, game);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestBomber = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}
export const grabLowestArgonouts = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestTorontoFc = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneMLSToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestLions = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Club Seats");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestWhitecaps = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneMLSToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Midle Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestUfcEdmontoon = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Floor");
        const midZoneCheck = zoneInvetory.includes("Lower Bowl");
        const upZoneCheck = zoneInvetory.includes("Upper Bowl");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  return seatList;
}

export const grabLowestTigers = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Midle Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestRiders = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        //const lowerZoneCheck = [113,114,115,116,117,137,138,139,140];
        const middleZoneCheck = [312,313,314,315,316,317,237,238,239,240,241];
        const lowZoneCheck = zoneInvetory.includes("Lower Zone") && (Number(seat.available_quantity) > 1) /* && lowerZoneCheck.some(l => zoneInvetory.includes(l)) */;
        const midZoneCheck = zoneInvetory.includes("Middle Zone") && middleZoneCheck.some(m => zoneInvetory.includes(m));
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}
export const grabLowestBlacks = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneCFLToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Middle Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}

export const grabLowestCFLFromTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  switch (selectedTeam) {
    case "calgary-stampeders": {
      return grabLowestSatmpeders(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "edmonton-elks": {
      return grabLowestElks(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "winnipeg-blue-bombers": {
      return grabLowestBomber(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "bc-lions": {
      return grabLowestLions(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "hamilton-tigercats": {
      return grabLowestTigers(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "grey-cup": {
      return grabLowestLions(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
    
    case "saskatchewan-roughriders": {
      return grabLowestRiders(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "toronto-argonauts": {
      return grabLowestArgonouts(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "ottawa-redblacks": {
      return grabLowestBlacks(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    default: {
      return grabLowestSatmpeders(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
  }
};

export const grabLowestMLSFromTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  switch (selectedTeam) {
    case "vancouver-whitecaps-fc": {
      return grabLowestWhitecaps(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    case "toronto-fc": {
      return grabLowestTorontoFc(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    default: {
      return grabLowestSatmpeders(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }
  }
};

export const grabJaysLowestFromTE =  (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((section) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const trimTESection =
          seat.section.length > 3 ? seat.section.slice(0, -1) : seat.section;

        const sectionName = seat.section;
        const isWestJets = sectionName.toLowerCase() == 'westjet flight deck';
        const teSection = !isNaN(Number(trimTESection))
          ? Number(trimTESection)
          : 0;
        const grabLowerZone = teSection > 100 && teSection < 149;
        const grabMiddleZone = (teSection > 203 && teSection < 244) || (isWestJets);
        const grabUpperZone = teSection > 507 && teSection < 541;
        const jaysSectionType = {
          low: grabLowerZone,
          mid: grabMiddleZone,
          up: grabUpperZone,
        };
        const jaysTeSection = seat.tevo_section_name.split(" ").pop();
        const jaysTeSectionChar =
          jaysTeSection.length === 4 ? seat.section.slice(-1) : "";

          if (seat.section === '236L') {
            console.log("seat 236l outside if", new Date(game.date), seat);
          }

        if ((jaysSectionType[section] && jaysTeSectionChar !== "R")) {
          if (seat.section === '236L') { 
            console.log("seat 236l inside if", new Date(game.date), seat);
          }
          const zoneInvetory = `${formatJaysTEZoneToFF(seat, lowestParams)}${
            jaysTeSectionChar ? " " : ""
          }${jaysTeSectionChar}`;
          const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
          const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
          const seatItem = {
            price: grabUpperZone || isWestJets ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: isWestJets ? sectionName : zoneInvetory,
            zoneNo: teSection,
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const isZoneNumber = true;
          const isRowNumber = (!isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0) || isWestJets;
          const isZoneExist = (seatItem.zone !== "---") || isWestJets;
          const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
          const seatData = {};
          seatData[section] = seatItem;
          !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            isRowNumber &&
            seatList.push(seatData);
            !isExcludedSeat &&
            isZoneExist &&
            isZoneNumber &&
            isRowNumber && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  return seatList;
};

export const grabLeafsRapsLowerTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  const slicedListing = ticket_groups.filter((seat) => {
    const numberSection = !isNaN(Number(seat.section))
      ? Number(seat.section)
      : 0;
    return (
      numberSection > 199 &&
      seat.available_quantity > 1 &&
      !formatTEToExclude(seat, game)
    );
  });
  const slicedListingLower = ticket_groups.filter(
    (seat) =>
      seat.available_quantity > 1 &&
      !formatTEToExclude(seat, game) &&
      leafsRaptorsToIncludeclude(seat.section)
  );
  let seatList = [];
  if (slicedListing.length > 0) {
    for (let i = 0; i < slicedListing.length; i++) {
      const seat = slicedListing[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const convertedPrice = convertPriceToCADNoFee(seat, currencyRate, game);
      const seatItem = {
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, selectedTeam),
        row: getRowSection(seat.row, selectedTeam),
        noOfSeats: Number(seat.available_quantity),
        tier: getTeamTier(selectedTeam, zoneInvetory),
        quantityOptions: [Number(seat.available_quantity)],
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        selectedTeam === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
      const seatData = {};
      seatData["up"] = seatItem;
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        seatList.push(seatData);
      if (seatList.length > 0) {
        break;
      }
    }
  }
  if (slicedListingLower.length > 0) {
    for (let i = 0; i < slicedListingLower.length; i++) {
      const seat = slicedListingLower[i];
      const zoneInvetory = formatTEZoneToFF(seat, lowestParams);
      const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
      const seatItem = {
        price: Number(convertedPrice),
        zone: zoneInvetory,
        zoneNo: getSectionZone(seat.section, selectedTeam),
        row: getRowSection(seat.row, selectedTeam),
        noOfSeats: Number(seat.available_quantity),
        tier: getTeamTier(selectedTeam, zoneInvetory),
        quantityOptions: [Number(seat.available_quantity)],
      };
      const isZoneNumber = !isNaN(Number(seatItem.zoneNo));
      const isRowNumber =
        selectedTeam === "ottawa-senators"
          ? teSenatorRowCheck(seatItem.row)
          : !isNaN(Number(seatItem.row)) && Number(seatItem.row) > 0;
      const isZoneExist = seatItem.zone !== "";
      const isExcludedSeat = excludeCheck(seatItem, selectedTeam);
      const seatData = {};
      seatData["low"] = seatItem;
      !isExcludedSeat &&
        isZoneExist &&
        isZoneNumber &&
        isRowNumber &&
        seatList.push(seatData);
      if (seatList.length > 0) {
        break;
      }
    }
  }
  return seatList;
};

export const getStoredLowest = async (state, setState, gameId, games) => {
  const { selectedTeam } = state;
  const newGames = games;
  await API.get(
    "v2",
    `marketplace/ticketEvo/getLowestPrice?gameId=${gameId}`
  ).then((data) => {
    if (newGames[selectedTeam]) {
      const currentIndex = newGames[selectedTeam].findIndex(
        (game) => game.id === gameId
      );
      const lowestData = data.length > 0 && data[0];
      const isStoredPriceLower =
        currentIndex > -1 &&
        newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice >
          lowestData.press_level_min_price &&
        lowestData;
      const isNewGameExist =
        currentIndex > -1 &&
        newGames[selectedTeam][currentIndex] &&
        newGames[selectedTeam][currentIndex].minPrices.pressLevelMinPrice;
      const isUseStored = isStoredPriceLower || !isNewGameExist;
      if (isUseStored && currentIndex > -1) {
        newGames[selectedTeam][currentIndex]["minPrices"][
          "pressLevelMinPrice"
        ] = Number(lowestData.press_level_min_price);
        setState({ ...state, games: newGames });
      }
    }
  });
};

export const getCurrencyExchangeRate = async (state, setState) => {
  await API.get("v2", `marketplace/ticketEvo/currencyRate`)
    .then((data) => {
      setState({ state, currencyRate: data.currencyRate });
      localStorage.setItem("currencyRate", JSON.stringify(data.currencyRate));
    })
    .catch((e) => {
      console.error(e);
    });
};

export const getFormattedTime = (timeFormat, game) => {
  const tbdTime = '12:00 AM'
  const timeValue = moment
  .tz(game.date, game.timezone)
  .format("h:mm A");

  const stringTimeFormat = (tbdTime === timeValue) ? 'TBD' : timeFormat;
  const isGreyCup = game.homeTeamSlug === 'grey-cup' && tbdTime === timeValue;
  
  return isGreyCup ? '' : stringTimeFormat;
}

export const isTimeTBD = (game) => {
  const tbdTime = '12:00 AM'
  const timeValue = moment
  .tz(game.date, game.timezone)
  .format("h:mm A");
  
  return tbdTime === timeValue;
}

export const findGridLowestPrice = (game) => {
  const secondLevelMinPrice = game.minPrices.secondLevelMinPrice
    ? `$${game.minPrices.secondLevelMinPrice.toFixed(0)}`
    : "-";
  const lowerLevelMinPrice = game.minPrices.lowerBowlMinPrice
    ? `$${game.minPrices.lowerBowlMinPrice.toFixed(0)}`
    : "-";
  const upperLevelMinPrice = game.minPrices.pressLevelMinPrice
    ? `$${game.minPrices.pressLevelMinPrice.toFixed(0)}`
    : "-";

  let minPrices = {
    upperLevelMinPrice,
    secondLevelMinPrice,
    lowerLevelMinPrice,
  };

  if (game.isSpecial) {
    switch (game.homeTeamSlug) {
      case "edmonton-oilers":
      case "vancouver-canucks":
        minPrices = {
          upperLevelMinPrice: upperLevelMinPrice,
          secondLevelMinPrice: lowerLevelMinPrice,
          lowerLevelMinPrice: secondLevelMinPrice,
        };
        break;
      default:
        break;
    }
  } else {
    switch (game.homeTeamSlug) {
      case "edmonton-elks":
      case "winnipeg-blue-bombers":
        minPrices = {
          upperLevelMinPrice: upperLevelMinPrice,
          secondLevelMinPrice: lowerLevelMinPrice,
          lowerLevelMinPrice: secondLevelMinPrice,
        };
        break;
      case "toronto-argonauts":
        minPrices = {
          upperLevelMinPrice: upperLevelMinPrice,
          secondLevelMinPrice: secondLevelMinPrice,
          lowerLevelMinPrice: lowerLevelMinPrice,
        };
        break;
      case "calgary-stampeders":
        minPrices = {
          upperLevelMinPrice: lowerLevelMinPrice,
          secondLevelMinPrice: secondLevelMinPrice,
          lowerLevelMinPrice: upperLevelMinPrice,
        };
        break;
      default:
        break;
    }
  }

  return minPrices;
}

export const getTimeAtLabels = (game) => {

  return game.homeTeamSlug === 'grey-cup' && isTimeTBD(game) ? ' ' : ' at ';
}

export const grabLowestAHLFromTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  switch (selectedTeam) {
    case "abbotsford-canucks": {
      return grabLowestWhitecaps(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    default: {
      return [];
    }
  }
};

export const grabLowestUFCFromTE = (
  ticket_groups,
  lowestParams,
  currencyRate,
  selectedTeam,
  game
) => {
  switch (selectedTeam) {
    case "ufc-fight-night": {
      return grabLowestUfcEdmontoon(
        ticket_groups,
        lowestParams,
        currencyRate,
        selectedTeam,
        game
      );
    }

    default: {
      return [];
    }
  }
};

export const grabLowestAbbotsford = (ticket_groups, lowestParams, currencyRate, selectedTeam, game) => {
  const slicedListing = ticket_groups.filter((seat) => seat.available_quantity > 1 && !formatTEToExclude(seat, game));
  let seatList = [];
  const sections = ["low", "mid", "up"];
  sections.forEach((sectI) => {
    if (slicedListing.length > 0) {
      let innerSeat = [];
      for (let i = 0; i < slicedListing.length; i++) {
        const seat = slicedListing[i];
        const zoneInvetory = formatTEZoneMLSToFF(seat, lowestParams);
        const convertedPriceNoFee = convertPriceToCADNoFee(seat, currencyRate, game);
        const convertedPrice = convertPriceToCAD(seat, currencyRate, game);
        const lowZoneCheck = zoneInvetory.includes("Lower Zone");
        const midZoneCheck = zoneInvetory.includes("Midle Zone");
        const upZoneCheck = zoneInvetory.includes("Upper Zone");
        const zonesType = {
          low: lowZoneCheck,
          mid: midZoneCheck,
          up: upZoneCheck,
        }
        if (zonesType[sectI]) {
          const seatItem = {
            price: upZoneCheck ? Number(convertedPriceNoFee) : Number(convertedPrice),
            zone: zoneInvetory,
            zoneNo: getSectionZone(seat.section, selectedTeam),
            row: getRowSection(seat.row, selectedTeam),
            noOfSeats: Number(seat.available_quantity),
            tier: getTeamTier(selectedTeam, zoneInvetory),
            quantityOptions: [Number(seat.available_quantity)],
          };
          const seatData = {};
          seatData[sectI] = seatItem;
          const isZoneExist = seatItem.zone !== "";
           isZoneExist && seatList.push(seatData);
           isZoneExist && innerSeat.push(seatData);
          if (innerSeat.length > 0) {
            break;
          }
        }
      }
    }
  });
  
  return seatList;
}