import React, { useState } from "react";
import { Button, withStyles } from "@material-ui/core";
import AccountMenu from "./account-menu";
import LoginForm from "../../containers/LoginForm";
import Logo from "../../images/logo.png";

const styles = (theme) => ({
  navContainer: {
    position: "relative",
    boxShadow: "0 4px 6px 0 rgb(0 0 0 / 3%)",
    backgroundColor: "#fafafa",
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
  },
  navContainerMobile: {
    position: "relative",
    boxShadow: "0 4px 6px 0 rgb(0 0 0 / 3%)",
    backgroundColor: "#fafafa",
    width: "100%",
    zIndex: "999",
  },
  nav: {
    maxWidth: 1366,
    height: "80px",
    margin: "0 auto",
    display: "flex",
  },
  logoContainer: {
    diplay: "block",
    margin: "auto",
  },
  callUsContainer: {
    position: "relative",
    padding: theme.spacing.unit * 2,
    flex: 1,
  },
  logo: {
    verticalAlign: "middle",
    width: "286px",
    height: "70px",
  },
  linksContainer: {
    display: "flex",
    padding: theme.spacing.unit * 2,
    flex: 1,
  },
  navLinks: {
    flex: 1,
    minHeight: theme.spacing.unit * 5,
    display: "flex",
    justifyContent: "flex-end",
  },
  accountLinksContainer: {
    minHeight: theme.spacing.unit * 5,
    borderRight: "1px solid #ddd",
  },
  button: {
    minWidth: theme.spacing.unit * 12,
    "&:first-child": {
      marginRight: `${theme.spacing.unit * 2}px`,
    },
  },
  questionsText: {
    color: "#00A0FF",
    fontWeight: "bold",
    width: "90%",
    marginTop: "110px",
  },
  phoneNumberText: {
    color: "#5F666F",
    fontWeight: "bold",
    width: "90%",
    marginTop: "110px",
  },
  sellButton: {
    height: '46px',
    minWidth: theme.spacing.unit * 12,
    margin: `0 ${theme.spacing.unit * 2}px`,
    backgroundColor: "#00de94",
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#00c281",
    },
    textTransform: "none",
  },
  registerButton: {
    height: '46px',
    margin: `0 ${theme.spacing.unit}px`,
    color: "#000000",
    textTransform: "none",
  },
  loginButton: {
    height: '46px',
    margin: `0 ${theme.spacing.unit}`,
    backgroundColor: "#00a0ff",
    color: "white",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#0098f3",
    },
    textTransform: "none",
  },
  [theme.breakpoints.down("md")]: {
    button: {
      margin: `0`,
    },
  },
});
const loginText = "Log In";
const singUpText = "Sign Up";
const sellTicketsText = "Sell Tickets";

const renderSellBtn = ({ classes }) => {
  return (
    <Button
      className={classes.sellButton}
      href="/sell"
      variant="extendedFab"
    >
      {sellTicketsText}
    </Button>
  )
}
const renderRegisterBtn = ({ classes}) => {
  return (
    <Button
      href="/signup"
      className={classes.registerButton}
    >
      {singUpText}
    </Button>
  );
};
const renderLoginBtn = ({ classes, handleLogin }) => {
  return (
    <Button 
      className={classes.loginButton} 
      onClick={handleLogin}
      variant="extendedFab"
    >
      {loginText}
    </Button>
  );
};

const desktopNav = ({ classes, isAuthenticated, currUser, handleLogout, ...props }) => {
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const handleLogin = () => {
    setIsLoginFormOpen(true);
  };

  const handleCloseLoginForm = () => {
    setIsLoginFormOpen(false);
  };

  return (
    <div
      className={
        window.screen.width < 962
          ? classes.navContainerMobile
          : classes.navContainer
      }
    >
      <nav className={classes.nav} position="relative">
        <a
          className={classes.callUsContainer}
          href="tel:+1-403-768-2298"
          style={{ textDecoration: "none" }}
        >
          <span className={classes.questionsText}>Questions? Call us: </span>
          <br />
          <span className={classes.phoneNumberText}>1-403-768-2298</span>
        </a>
        <div className={classes.logoContainer}>
          <a href="/" className={classes.logo}>
            <img
              src={Logo}
              alt="FansFirst Logo"
              height="32"
              className={classes.logo}
            />
          </a>
        </div>
        <div className={classes.linksContainer}>
          <div className={classes.navLinks}>
            {isAuthenticated && currUser ? "" : renderSellBtn({ classes })}
            <div className={classes.accountLinksContainer} />
            {isAuthenticated && currUser ? (
              <AccountMenu currUser={currUser} handleLogout={handleLogout} />
            ) : (
              <React.Fragment>
                {renderRegisterBtn({ classes })}
                {renderLoginBtn({ classes, handleLogin })}
                {isLoginFormOpen && (
                  <LoginForm
                    isAuthenticated={isAuthenticated}
                    currUser={currUser}
                    handleLogout={handleLogout}
                    {...props}
                    isOpen={isLoginFormOpen}
                    handleClose={handleCloseLoginForm}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default withStyles(styles)(desktopNav);
