import React, { Component } from "react";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import moment from "moment";
import { 
  Card, 
  CardContent, 
  Divider, 
  Grid, 
  Typography,
  withStyles,
} from "@material-ui/core";

import ProfileWidget from "../components/widgets/ProfileWidget";
import TrackOrderWidget from "../components/widgets/TrackOrderWidget";
import ListingsWidget from "../components/widgets/ListingsWidget";

const styles = (theme) => ({
  root: {
    minWidth: 275,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      marginTop: "4.5rem",
    },
  },
  content: {
    minWidth: 275,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  titleText: {
    color: '#2196f3',
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "1.5rem",
    '& a': {
      color: "#2196f3",
      textDecoration: "none",
    },
    '& a:hover': { 
      color: "#1769aa",
    },
  },
  divider: {
    margin: '.5rem 0',
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      statusOrders: null,
      isLoading: true,
      showResult: false,
    };
    this.setOrders = this.setOrders.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (!this.props.isAuthenticated || !this.props.currUser) {
      this.props.history.push("/login");
    }
    if (this.props.currUser && !this.props.currUser.email_verified) {
      this.props.history.push(`/email-verification`);
    }
  
    try {
      await this.setOrders();
      if (this._isMounted && this.state.statusOrders && this.state.statusOrders.length === 0) {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setOrders = async () => {
    const order = await this.getInitOrders();
    const orderData = order.orders;
    const sortedOrder = _.chain(orderData)
    .filter(
      (order) => !order.isRefunded
    )
    .orderBy("game.date", "asc")
    .value();

    if (this._isMounted) {
      this.setState({
        statusOrders: sortedOrder,
        isLoading: false,
        showResult: true,
      });
    }
  };
  
  currentDateString() {
    const parseDateTz = moment.tz('America/Edmonton').startOf('day').valueOf();
    return parseDateTz;
  }

  getInitOrders = async () => {
    try {
      const { currUser } = this.props;
      const limit = 5;
      
      const params = [];
      if(currUser){
        params.push(`buyerEmail=${encodeURIComponent(currUser.email.toLowerCase().replace(/\s+/g, ''))}`);
        params.push('gameStartDate=' + this.currentDateString());
        const queryString = params.join('&');

        const timeout = 10000; // 10 seconds
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, timeout);

        try {
          const response = await API.get(
            "v2",
            `marketplace/orders/search?${queryString}&limit=${limit}`,
            {
              signal: controller.signal,
            }
          );
          clearTimeout(timeoutId);
          return response;
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('API call timed out');
          } else {
            console.log(error);
          }
          throw error;
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  renderProfile() {
    const { classes, currUser, setUserAttributes} = this.props;

    return (
      <main>
        <Card className={classes.root}>
          <CardContent>
            <Typography 
              component="h2" 
              className={classes.titleText}
            >
              Profile
            </Typography>
            <Divider className={classes.divider}/>
            <ProfileWidget 
              setUserAttributes={setUserAttributes}
              currUser={currUser}
            />
          </CardContent>
        </Card>
      </main>
    );
  }

  renderTrackOrder() {
    const { currUser } = this.props;
    const { statusOrders, isLoading, showResult } = this.state;

    return (
      <TrackOrderWidget
        statusOrders={statusOrders}
        currUser={currUser}
        isLoading={isLoading}
        showResult={showResult}
      />
    );
  }

  renderListings() {
    const { classes, currUser} = this.props;
    return (
      <main>
        <Card className={classes.content}>
          <CardContent>
            <Typography 
              component="h2" 
              className={classes.titleText}
            >
              <a href="/listings">Listings (Sell)</a>
            </Typography>
            <Divider className={classes.divider}/>
            <ListingsWidget 
              currUser={currUser}
            />
          </CardContent>
        </Card>
      </main>
    );
  }

  render() {
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Dashboard" />
          <meta name="keywords" content={`dashboard user, user profile, order status, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Dashboard" />
          <meta property="og:description" content="Dashboard" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            {this.renderProfile()}
            {this.renderTrackOrder()}
            {this.renderListings()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);