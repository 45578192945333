const metaTags = {
    "calgary-flames": {
        headline: "Calgary Flames Tickets",
        metaTitle: "Calgary Flames Schedule Tickets | 2024-2025 Flames Games | FansFirst",
        metaDescription: "Check the 2024-2025 Calgary Flames schedule and grab your tickets now! Join the excitement and cheer for the Flames with FansFirst. Book your seats today!",
    }, 
    "edmonton-oilers": {
        headline: "Edmonton Oilers Tickets",
        metaTitle: "Book Edmonton Oilers Tickets At the Best Price - Buy 2024-2025 Ticket",
        metaDescription: "Get the best deals on Edmonton Oilers 2024-2025 tickets. Buy now and secure your spot at the game. Don't miss out on the action—grab your tickets today!",
    }, 
    "vancouver-canucks": {
        headline: "Vancouver Canucks Tickets",
        metaTitle: "Buy 2024-2025 Vancouver Canucks Tickets - Book Games At Best Prices",
        metaDescription: "Secure your 2024-2025 Vancouver Canucks tickets at unbeatable prices. Book now to catch all the excitement and action live. Don't miss out—get your tickets today!",
    }, 
    "winnipeg-jets": {
        headline: "Winnipeg Jets Tickets",
        metaTitle: "Book 2024-2025 Winnipeg Jets Tickets - NHL Tickets & Schedule",
        metaDescription: "Get your 2024-2025 Winnipeg Jets tickets now! Check the NHL schedule and secure your seats for the season. Don't miss out—book your tickets today!",
    }, 
    "toronto-raptors": {
        headline: "Toronto Raptors Tickets",
        metaTitle: "Toronto Raptors Games - Buy and Sell Tickets Online | Toronto Blue Jays tickets",
        metaDescription: "Buy/sell Toronto raptors games and blue jays tickets online with FansFirst. We offer the best deals and a hassle-free ticket-buying and selling experience. Visit our website today!",
    }, 
    "ottawa-senators": {
        headline: "Ottawa Senators Tickets",
        metaTitle: "Ottawa Senators Games - Buy Tickets Online | FansFirst",
        metaDescription: "Buy and sell Ottawa Senators game tickets online with FansFirst. Get the best prices and a hassle-free experience. Visit our website to secure your seats today!",
    }, 
    "toronto-maple-leafs": {
        headline: "Toronto Maple Tickets",
        metaTitle: "Watch Toronto Maple Leafs Games - Book Tickets Online on FansFirst",
        metaDescription: "Buy and sell Toronto Maple Leafs tickets easily on FansFirst. Enjoy hassle-free transactions and great deals. Visit our site today for the best prices!",
    }, 
    "toronto-blue-jays": {
        headline: "Toronto Blue Jays Tickets",
        metaTitle: "Toronto Blue Jays Tickets - MLB Games & Schedule | FansFirst",
        metaDescription: "Get Toronto Blue Jays tickets from season ticket holders at the best price. Enjoy live games at Rogers Centre with no fees or markup. Check prices and schedules here.",
    }, 
    "edmonton-elks": {
        headline: "Edmonton Elks Tickets",
        metaTitle: "Buy Edmonton Elks Tickets - Watch CFL Games & Schedule",
        metaDescription: "Get Edmonton Elks tickets from season ticket holders at the best price. Enjoy live games at Commonwealth Stadium with no fees or markup. Check prices at Fans First.",
    }, 
    "winnipeg-blue-bombers": {
        headline: "Winnipeg Blue Bombers Tickets",
        metaTitle: "Book Winnipeg Blue Bombers Tickets - Buy CFL Games & Schedule",
        metaDescription: "Buy Winnipeg Blue Bombers tickets directly from season ticket holders at IG Field with no fees. Check prices and schedules now for CFL games!",
    }, 
    "toronto-argonauts": {
        headline: "Toronto Argonauts Tickets",
        metaTitle: "Buy Toronto Argonauts Tickets | Book CFL Tickets & Schedule",
        metaDescription: "Buy Toronto Argonauts tickets from season ticket holders at the best prices with no fees. Get CFL tickets and BMO Field game schedules. Click for details!",
    }, 
    "calgary-stampeders": {
        headline: "Calgary Stampeders Tickets",
        metaTitle: "Book Calgary Stampeders Tickets | Buy 2024-2025 Events CFL Tickets",
        metaDescription: "Get your 2024-2025 Calgary Stampeders tickets now! Secure your spot at CFL events and experience the excitement live. Book your tickets today!",
    }, 
    "ottawa-redblacks": {
        headline: "Ottawa Redblacks Tickets",
        metaTitle: "Buy Ottawa Redblacks Tickets | CFL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Ottawa Redblacks tickets from season ticket holders at the best price and enjoy a live game at TD Place without any fees or markup. To see Ottawa Redblacks ticket prices and schedules, click here.",
    }, 
    "bc-lions": {
        headline: "BC Lions Tickets",
        metaTitle: "Book BC Lions Tickets | Watch CFL Games & Schedule | Fansfirst",
        metaDescription: "Get BC Lions tickets at the best prices from season ticket holders! Enjoy live games at BC Place with no fees or markup. Click for prices and schedules.",
    }, 
    "saskatchewan-roughriders": {
        headline: "Saskatchewan Roughriders Tickets",
        metaTitle: "Buy Saskatchewan Roughriders Tickets | CFL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Saskatchewan Roughriders tickets from season ticket holders at the best price and enjoy a live game at Mosaic Stadium without any fees or markup. To see Saskatchewan Roughriders ticket prices and schedules, click here.",
    }, 
    "hamilton-tigercats": {
        headline: "Hamilton Tigercats Tickets",
        metaTitle: "Buy Hamilton Tigercats Tickets | CFL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Tigercats tickets from season ticket holders at the best price and enjoy a live game at Tim Hortons Field Stadium without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "grey-cup": {
        headline: "Grey Cup Tickets",
        metaTitle: "Buy Grey Cup Tickets | CFL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Grey Cup tickets from season ticket holders at the best price and enjoy a live game at Tim Hortons Field Stadium without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "calgary-wranglers": {
        headline: "Calgary Wranglers Tickets",
        metaTitle: "Buy Calgary Wranglers Tickets | AHL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Calgary Wranglers tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "nhl-special-games": {
        headline: "All Star Game Tickets",
        metaTitle: "All Star Game - Buy Tickets Online on FansFirst",
        metaDescription: "You can purchase and sell All Star Game game tickets online easily and without hassle through FansFirst. Take advantage of our great deals by visiting our website today!",
    }, 
    "manitoba-moose": {
        headline: "Manitoba Moose Tickets",
        metaTitle: "Buy Manitoba Moose Tickets | AHL Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Manitoba Moose tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "vancouver-whitecaps-fc": {
        headline: "Vancouver Whitecaps FC Tickets",
        metaTitle: "Buy Vancouver Whitecaps FC Tickets | MLS Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Vancouver Whitecaps FC tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "toronto-fc": {
        headline: "Toronto FC Tickets",
        metaTitle: "Buy Toronto FC Tickets | MLS Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Toronto FC tickets from season ticket holders at the best price and enjoy a live game at Saddledome without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "world-junior": {
        headline: "World Junior Tickets",
        metaTitle: "Buy World Junior | IIHF Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your World Junior from season ticket holders at the best price and enjoy a live game at TD Place without any fees or markup. To see World Junior ticket prices and schedules, click here.",
    }, 
    "abbotsford-canucks": {
        headline: "Abbotsford Canucks Tickets",
        metaTitle: "Buy Abbotsford Canucks Tickets | MLS Tickets & Schedule | fansfirst.ca",
        metaDescription: "Buy your Abbotsford Canucks tickets from season ticket holders at the best price and enjoy a live game at Abbotsford Centre without any fees or markup. To see Tigercats Tickets ticket prices and schedules, click here.",
    }, 
    "taylor-swift": {
        headline: "Taylor Swift Tickets",
        metaTitle: "Buy Taylor Swift Tickets | Concert Tickets & Schedule | fansfirst.ca",
        metaDescription: "You can purchase and sell Taylor Swift tickets online easily and without hassle through FansFirst. Take advantage of our great deals by visiting our website today!",
    },
    "ufc-fight-night": {
        headline: "UFC Fight Night Tickets",
        metaTitle: "Buy UFC Fight Night Tickets | Concert Tickets & Schedule | fansfirst.ca",
        metaDescription: "You can purchase and sell UFC Fight Night tickets online easily and without hassle through FansFirst. Take advantage of our great deals by visiting our website today!",
    }
  };
  export default metaTags;