export const getTeamTier = (teamSlug, seatLevel) => {
  const tierMap = {
    "calgary-flames": {
      "Press Level": "3",
      "Second Level": "2",
      REST: "2",
      "Lower Club": "1",
      "Lower Bowl": "1",
    },
    "calgary-wranglers": {
      "Press Level": "3",
      "Second Level": "2",
      REST: "2",
      "Lower Club": "1",
      "Lower Bowl": "1",
    },
    "toronto-blue-jays": {
      "Upper Zone": "3",
      "TM LOUNGE": "2",
      "WESTJET FLIGHT DECK": "2",
      "Middle Zone": "2",
      "Middle Zone AL": "2",
      "Middle Zone L": "2",
      "Middle Zone R": "2",
      "Middle Zone AR": "2",
      "Middle Zone BR": "2",
      "Middle Zone BL": "2",
      "Middle Zone W": "2",
      "Lower Zone": "1",
      "Lower Zone W": "1",
      "Lower Zone A": "1",
      "Lower Zone B": "1",
      "Lower Zone C": "1",
      "Lower Zone D": "1",
      "Lower Zone L": "1",
      "Lower Zone R": "1",
      "Lower Zone AL": "1",
      "Lower Zone AR": "1",
      "Lower Zone BL": "1",
      "Lower Zone BR": "1",
      "Lower Zone CL": "1",
      "Lower Zone CR": "1",
      "Lower Zone DL": "1",
      "Lower Zone DR": "1",
      "Lower Zone WR": "1",
      "Lower Zone WL": "1",
    },
    "vancouver-canucks": {
      "Upper Bowl": "3",
      "Upper Bowl - No Alcohol": "3",
      "Upper Bowl - Balcony": "3",
      "Lower Bowl": "2",
      "Lower Bowl - No Alcohol": "2",
      "Club Lower": "1",
      "Sports Bar": "3",
    },
    "winnipeg-jets": {
      "Upper Zone": "3",
      Loge: "1",
      "Middle Zone": "2",
      "Lower Zone": "1",
      "PREMIUM SUITE LOUNGE": "2"
    },
    "manitoba-moose": {
      "Upper Zone": "3",
      Loge: "1",
      "Middle Zone": "2",
      "Lower Zone": "1",
      "PREMIUM SUITE LOUNGE": "2"
    },
    "edmonton-oilers": {
      "Upper Zone": "3",
      "Upper Bowl": "3",
      Loge: "1",
      "Lower Zone": "2",
      "Lower Bowl": "2",
      Other: "1",
      "Sky Lounge AA": "1",
      "Sky Lounge BB": "1",
      "Sky Lounge CC": "1",
      "Sky Lounge DD": "1",
      "Sky Lounge EE": "1",
      "Sky Lounge A": "1",
      "Sky Lounge B": "1",
      "Sky Lounge C": "1",
      "Sky Lounge D": "1",
      "Sky Lounge E": "1",
      "Sportsnet Club AA": "1",
      "Sportsnet Club BB": "1",
      "Sportsnet Club CC": "1",
      "Sportsnet Club DD": "1",
      "Sportsnet Club EE": "1",
      "Sportsnet Club A": "1",
      "Sportsnet Club B": "1",
      "Sportsnet Club C": "1",
      "Sportsnet Club D": "1",
      "Sportsnet Club E": "1",
    },
    "toronto-maple-leafs": {
      "Upper Zone": "3",
      Suite: "1",
      "Suite A": "1",
      "Lower Zone": "2",
      GBOX: "1",
      Molson: "1",
    },
    "ottawa-senators": {
      "Upper Zone": "3",
      "Middle Zone": "2",
      "Lower Zone": "1",
      "Lower Zone C": "1",
      Lodge: "3",
      Ledge: "3",
      Suite: "3",
      "Suite A": "3",
      "Suite B": "3",
      TLGTRA: "3",
      TLGTRB: "3",
    },
    "toronto-raptors": {
      "Upper Zone": "3",
      Suite: "1",
      "Suite A": "1",
      "Lower Zone": "2",
      GBOX: "1",
      Molson: "1",
      "Courtside North": "1",
      "Courtside East": "1",
      "Courtside South": "1",
      "Courtside West": "1",
      Courtside: "1",
    },
    "edmonton-elks": {
      "Upper Zone A": "3",
      "Upper Zone B": "3",
      "Upper Zone C": "3",
      "Upper Zone D": "3",
      "Upper Zone E": "3",
      "Upper Zone F": "3",
      "Upper Zone G": "3",
      "Upper Zone H": "3",
      "Upper Zone I": "3",
      "Upper Zone J": "3",
      "Upper Zone K": "3",
      "Upper Zone L": "3",
      "Upper Zone M": "3",
      "Upper Zone N": "3",
      "Upper Zone O": "3",
      "Upper Zone P": "3",
      "Upper Zone Q": "3",
      "Upper Zone R": "3",
      "Upper Zone S": "3",
      "Upper Zone T": "3",
      "Upper Zone U": "3",
      "Upper Zone V": "3",
      "Upper Zone W": "3",
      "Upper Zone X": "3",
      "Upper Zone Y": "3",
      "Upper Zone Z": "3",
      "Upper Zone AA": "3",
      "Upper Zone BB": "3",
      "Upper Zone CC": "3",
      "Upper Zone DD": "3",
      "Upper Zone EE": "3",
      "Upper Zone FF": "3",
      "Upper Zone MM": "3",
      "Upper Zone NN": "3",
      "Upper Zone OO": "3",
      "Upper Zone PP": "3",
      "Upper Zone QQ": "3",
      "Upper Zone RR": "3",
      "Lower Zone A": "2",
      "Lower Zone B": "2",
      "Lower Zone C": "2",
      "Lower Zone D": "2",
      "Lower Zone E": "2",
      "Lower Zone F": "2",
      "Lower Zone G": "2",
      "Lower Zone H": "2",
      "Lower Zone I": "2",
      "Lower Zone J": "2",
      "Lower Zone K": "2",
      "Lower Zone L": "2",
      "Lower Zone M": "2",
      "Lower Zone N": "2",
      "Lower Zone O": "2",
      "Lower Zone P": "2",
      "Lower Zone Q": "2",
      "Lower Zone R": "2",
      "Lower Zone T": "2",
      "Lower Zone U": "2",
      "Lower Zone V": "2",
      "Lower Zone W": "2",
      "Lower Zone X": "2",
      "Lower Zone Y": "2",
      "Lower Zone Z": "2",
      "Lower Zone AA": "2",
      "Lower Zone BB": "2",
      "Lower Zone CC": "2",
      "Lower Zone DD": "2",
      "Lower Zone EE": "2",
      "Lower Zone FF": "2",
      "Lower Zone GG": "2",
      "Lower Zone HH": "2",
      "Lower Zone JJ": "2",
      "Lower Zone KK": "2",
      "Lower Zone LL": "2",
      "Lower Zone MM": "2",
      "Lower Zone NN": "2",
      "Lower Zone OO": "2",
      "Lower Zone PP": "2",
      "Lower Zone QQ": "2",
      "Lower Zone RR": "2",
      "Lower Zone SS": "2",
      "Twisted Tea Party Deck": "1",
      "Coors Light Party Deck": "1",
      "Rooftop Patio": "1",
      "ENDZONE": "1"
    },
    "winnipeg-blue-bombers": {
      "Upper Zone": "3",
      "Lower Zone": "2",
      "Jim Beam Social": "1",
    },
    "toronto-argonauts": {
      "Upper Zone": "3",
      "Upper Zone A": "3",
      "Lower Zone": "2",
      "Lower Zone A": "2",
      "Side Seats": "1",
    },
    "calgary-stampeders": {
      "Lower Zone": "3",
      "CLUBHOUSE": "1",
    },
    "ottawa-redblacks": {
      "Upper Zone AA": "3",
      "Upper Zone BB": "3",
      "Upper Zone CC": "3",
      "Upper Zone DD": "3",
      "Upper Zone EE": "3",
      "Upper Zone FF": "3",
      "Upper Zone GG": "3",
      "Upper Zone HH": "3",
      "Upper Zone II": "3",
      "Upper Zone JJ": "3",
      "Upper Zone NN": "3",
      "Upper Zone OO": "3",
      "Upper Zone PP": "3",
      "Upper Zone QQ": "3",
      "Upper Zone RR": "3",
      "Upper Zone SS": "3",
      "Upper Zone TT": "3",
      "Upper Zone UU": "3",
      "Upper Zone VV": "3",
      "Upper Zone WW": "3",
      "Upper Zone XX": "3",
      "Upper Zone MM": "3",
      "Middle Zone": "2",
      "Lower Zone A": "1",
      "Lower Zone B": "1",
      "Lower Zone C": "1",
      "Lower Zone D": "1",
      "Lower Zone E": "1",
      "Lower Zone F": "1",
      "Lower Zone G": "1",
      "Lower Zone H": "1",
      "Lower Zone I": "1",
      "Lower Zone J": "1",
      "Lower Zone K": "1",
      "Lower Zone L": "1",
      "Lower Zone M": "1",
      "Lower Zone N": "1",
      "Lower Zone O": "1",
      "Lower Zone P": "1",
      "Lower Zone Q": "1",
      "Lower Zone R": "1",
      "Lower Zone T": "1",
      "Lower Zone U": "1",
      "Lower Zone V": "1",
      "Lower Zone W": "1",
      "Lower Zone X": "1",
      "Lower Zone Y": "1",
      "Lower Zone Z": "1",
      "Field": "1",
    },
    "bc-lions": {
      "Upper Zone": "3",
      "Middle Zone": "2",
      "Club Seats": "2",
      "Lower Zone": "1",
      "The Den": "1",      
    },
    "saskatchewan-roughriders": {
      "Upper Zone": "3",
      "Middle Zone": "2",
      "Middle Zone A": "2",
      "Lower Zone": "1",
      "PIL COUNTRY - STANDING ROOM ONLY": "1"
    },
    "hamilton-tigercats": {
      "Upper Zone": "3",
      "Premium Zone C": "2",
      "Lower Zone": "1",
      "The Striply": "1",
      "Touchdown Lounge": "1",
      "Stelco Northend": "1",
    },
    "grey-cup": {
      "Upper Zone": "3",
      "Middle Zone": "2",
      "Club Seats": "2",
      "Lower Zone": "1",
      "The Den": "1",   
    },
    "vancouver-whitecaps-fc": {
      "General Admission": "3",
      "Middle Zone": "2",
      "Lower Zone": "1",
    },
    "toronto-fc": {
      "Upper Zone": "3",
      "Upper Zone A": "3",
      "Lower Zone": "2",
      "Lower Zone A": "2",
      "Side Seats": "1",
    },
    "abbotsford-canucks": {
      "Lower Zone": "1",
      "Lower Zone A": "1",
      "Lower Zone B": "1",
      "ADA": "1",
    },
    "ufc-fight-night": {
      "Upper Zone": "3",
      "Upper Bowl": "3",
      Loge: "1",
      "Lower Zone": "2",
      "Lower Bowl": "2",
      Other: "1",
      "Sky Lounge AA": "1",
      "Sky Lounge BB": "1",
      "Sky Lounge CC": "1",
      "Sky Lounge DD": "1",
      "Sky Lounge EE": "1",
      "Sky Lounge A": "1",
      "Sky Lounge B": "1",
      "Sky Lounge C": "1",
      "Sky Lounge D": "1",
      "Sky Lounge E": "1",
      "Sportsnet Club AA": "1",
      "Sportsnet Club BB": "1",
      "Sportsnet Club CC": "1",
      "Sportsnet Club DD": "1",
      "Sportsnet Club EE": "1",
      "Sportsnet Club A": "1",
      "Sportsnet Club B": "1",
      "Sportsnet Club C": "1",
      "Sportsnet Club D": "1",
      "Sportsnet Club E": "1",
      "Floor North": "1",
      "Floor South": "1",
      "Floor East": "1",
      "Floor West": "1",
    }
  };

  return tierMap[teamSlug] && tierMap[teamSlug][seatLevel]
    ? tierMap[teamSlug][seatLevel]
    : "2";
};
