// Playoff Games 2023
export const raptors2023TEId = [
  {
    "playoffSequence": 202301,
      "id": 2260186,
      "name": "NBA Playoffs Play In: TBD at Toronto Raptors (Home Game 1) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202302,
      "id": 2260187,
      "name": "NBA Playoffs Play In: TBD at Toronto Raptors (Home Game 2) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202311,
      "id": 2260188,
      "name": "NBA Eastern Conference Quarterfinals: TBD at Toronto Raptors (Home Game 1) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202312,
      "id": 2260189,
      "name": "NBA Eastern Conference Quarterfinals: TBD at Toronto Raptors (Home Game 2) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202315,
      "id": 2260190,
      "name": "NBA Eastern Conference Quarterfinals: TBD at Toronto Raptors (Home Game 3) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202317,
      "id": 2260191,
      "name": "NBA Eastern Conference Quarterfinals: TBD at Toronto Raptors (Home Game 4) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202321,
      "id": 2260192,
      "name": "NBA Eastern Conference Semifinals: TBD at Toronto Raptors (Home Game 1) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202322,
      "id": 2260193,
      "name": "NBA Eastern Conference Semifinals: TBD at Toronto Raptors (Home Game 2) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202325,
      "id": 2260194,
      "name": "NBA Eastern Conference Semifinals: TBD at Toronto Raptors (Home Game 3) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202327,
      "id": 2260195,
      "name": "NBA Eastern Conference Semifinals: TBD at Toronto Raptors (Home Game 4) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202331,
      "id": 2260196,
      "name": "NBA Eastern Conference Finals: TBD at Toronto Raptors (Home Game 1) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202332,
      "id": 2260197,
      "name": "NBA Eastern Conference Finals: TBD at Toronto Raptors (Home Game 2) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202335,
      "id": 2260198,
      "name": "NBA Eastern Conference Finals: TBD at Toronto Raptors (Home Game 3) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202337,
      "id": 2260199,
      "name": "NBA Eastern Conference Finals: TBD at Toronto Raptors (Home Game 4) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202341,
      "id": 2260200,
      "name": "NBA Finals: TBD at Toronto Raptors (Home Game 1) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202342,
      "id": 2260202,
      "name": "NBA Finals: TBD at Toronto Raptors (Home Game 2) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202345,
      "id": 2260203,
      "name": "NBA Finals: TBD at Toronto Raptors (Home Game 3) (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202347,
      "id": 2260205,
      "name": "NBA Finals: TBD at Toronto Raptors (Home Game 4) (Date TBD) (If Necessary)"
  }
];

export const leafs2023TEId = [
  {
    playoffSequence: 202311,
    id: 2263259,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202312,
    id: 2263260,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202315,
    id: 2263261,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202317,
    id: 2263262,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202321,
    id: 2263264,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202322,
    id: 2263265,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202325,
    id: 2263266,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202327,
    id: 2263267,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202331,
    id: 2263268,
    name: "NHL Eastern Conference Finals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202332,
    id: 2263269,
    name: "NHL Eastern Conference Finals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202335,
    id: 2263270,
    name: "NHL Eastern Conference Finals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202337,
    id: 2263271,
    name: "NHL Eastern Conference Finals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202341,
    id: 2263273,
    name: "NHL Stanley Cup Finals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202342,
    id: 2263275,
    name: "NHL Stanley Cup Finals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202345,
    id: 2263276,
    name: "NHL Stanley Cup Finals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202347,
    id: 2263278,
    name: "NHL Stanley Cup Finals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
];

export const blueJays2023TEId = [
  {
    "playoffSequence": 202301,
      "id": 2382994,
      "name": "AL Wild Card: TBD at Toronto Blue Jays - Home Game 1 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202302,
      "id": 2382996,
      "name": "AL Wild Card: TBD at Toronto Blue Jays - Home Game 2 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202303,
      "id": 2382997,
      "name": "AL Wild Card: TBD at Toronto Blue Jays - Home Game 3 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202304,
      "id": 2382998,
      "name": "ALDS: TBD at Toronto Blue Jays - Home Game 1 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202305,
      "id": 2382999,
      "name": "ALDS: TBD at Toronto Blue Jays - Home Game 2 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202306,
      "id": 2383000,
      "name": "ALDS: TBD at Toronto Blue Jays - Home Game 3 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202307,
      "id": 2383001,
      "name": "ALCS: TBD at Toronto Blue Jays - Home Game 1 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202308,
      "id": 2383002,
      "name": "ALCS: TBD at Toronto Blue Jays - Home Game 2 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202309,
      "id": 2383003,
      "name": "ALCS: TBD at Toronto Blue Jays - Home Game 3 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202310,
      "id": 2383004,
      "name": "ALCS: TBD at Toronto Blue Jays - Home Game 4 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202311,
      "id": 2383005,
      "name": "World Series: TBD at Toronto Blue Jays - Home Game 1 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202312,
      "id": 2383007,
      "name": "World Series: TBD at Toronto Blue Jays - Home Game 2 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202313,
      "id": 2383008,
      "name": "World Series: TBD at Toronto Blue Jays - Home Game 3 (Date TBD) (If Necessary)"
  },
  {
    "playoffSequence": 202314,
      "id": 2383009,
      "name": "World Series: TBD at Toronto Blue Jays - Home Game 4 (Date TBD) (If Necessary)"
  }
];

export const blueBombers2023TEId = [
  {
    playoffSequence: 202301,
    id: '2477393',
    name: "Western Final: TBD at Winnipeg Blue Bombers",
  }
];

export const argonauts2023TEId = [
  {
    playoffSequence: 202301,
    id: '2441832',
    name: "Eastern Final: TBD at Toronto Argonauts",
  }
];

export const lions2023TEId = [
  {
    playoffSequence: 202301,
    id: '2450220',
    name: "Western Semi-Final: Calgary Stampeders at BC Lions",
  }
];

// Playoff Games 2024
export const nhlSpecialGames2024TEId = [
  {
    playoffSequence: 202401,
    id: '2375728',
    name: "NHL All Star SuperSkills Competition",
  },
  {
    playoffSequence: 202402,
    id: '2375729',
    name: "NHL All Star Game",
  }
];

export const blueJays2024TEId = [
  {
    playoffSequence: 202401,
    id: '2495247',
    name: "Colorado Rockies at Toronto Blue Jays",
  },
  {
    playoffSequence: 202402,
    id: '2495249',
    name: "Colorado Rockies at Toronto Blue Jays",
  },
  {
    playoffSequence: 202403,
    id: '2495262',
    name: "Colorado Rockies at Toronto Blue Jays",
  }
];

export const jets2024TEId = [
  {
    playoffSequence: 202411,
    id: 2613292,
    name: 'NHL Western Conference Quarterfinals: TBD at Winnipeg Jets (Home Game 1) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202412,
    id: 2613314,
    name: 'NHL Western Conference Quarterfinals: TBD at Winnipeg Jets (Home Game 2) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202413,
    id: 2613407,
    name: 'NHL Western Conference Quarterfinals: TBD at Winnipeg Jets (Home Game 3) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202414,
    id: 2613447,
    name: 'NHL Western Conference Quarterfinals: TBD at Winnipeg Jets (Home Game 4) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202415,
    id: 2621132,
    name: 'NHL Western Conference Semifinals: TBD at Winnipeg Jets (Home Game 1) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202416,
    id: 2621145,
    name: 'NHL Western Conference Semifinals: TBD at Winnipeg Jets (Home Game 2) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202417,
    id: 2621156,
    name: 'NHL Western Conference Semifinals: TBD at Winnipeg Jets (Home Game 3) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202418,
    id: 2621168,
    name: 'NHL Western Conference Semifinals: TBD at Winnipeg Jets (Home Game 4) (Date TBD) (If Neccessary)',
  },
  {
    playoffSequence: 202331,
    id: 2622782,
    name: "NHL Western Conference Finals: TBD at Winnipeg Jets (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202332,
    id: 2622788,
    name: "NHL Western Conference Finals: TBD at Winnipeg Jets (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202335,
    id: 2622789,
    name: "NHL Western Conference Finals: TBD at Winnipeg Jets (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202337,
    id: 2622790,
    name: "NHL Western Conference Finals: TBD at Winnipeg Jets (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202341,
    id: 2622978,
    name: "NHL Stanley Cup Finals: TBD at Winnipeg Jets (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202342,
    id: 2622979,
    name: "NHL Stanley Cup Finals: TBD at Winnipeg Jets (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202345,
    id: 2622980,
    name: "NHL Stanley Cup Finals: TBD at Winnipeg Jets (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202347,
    id: 2622981,
    name: "NHL Stanley Cup Finals: TBD at Winnipeg Jets (Home Game 4) (Date TBD) (If Necessary)",
  },
];

export const oilers2024TEId = [
  {
    playoffSequence: 202421,
    id: 2613296,
    name: "NHL Western Conference Quarterfinals: TBD at Edmonton Oilers (Home Game 1) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202422,
    id: 2613366,
    name: "NHL Western Conference Quarterfinals: TBD at Edmonton Oilers (Home Game 2) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202423,
    id: 2613410,
    name: "NHL Western Conference Quarterfinals: TBD at Edmonton Oilers (Home Game 3) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202424,
    id: 2613450,
    name: "NHL Western Conference Quarterfinals: TBD at Edmonton Oilers (Home Game 4) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202425,
    id: 2621129,
    name: "NHL Western Conference Semifinals: TBD at Edmonton Oilers (Home Game 1) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202426,
    id: 2621148,
    name: "NHL Western Conference Semifinals: TBD at Edmonton Oilers (Home Game 2) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202427,
    id: 2621159,
    name: "NHL Western Conference Semifinals: TBD at Edmonton Oilers (Home Game 3) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202428,
    id: 2621179,
    name: "NHL Western Conference Semifinals: TBD at Edmonton Oilers (Home Game 4) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202331,
    id: 2622805,
    name: "NHL Western Conference Finals: TBD at Edmonton Oilers (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202332,
    id: 2622806,
    name: "NHL Western Conference Finals: TBD at Edmonton Oilers (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202335,
    id: 2622807,
    name: "NHL Western Conference Finals: TBD at Edmonton Oilers (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202337,
    id: 2622808,
    name: "NHL Western Conference Finals: TBD at Edmonton Oilers (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202341,
    id: 2623004,
    name: "NHL Stanley Cup Finals: TBD at Edmonton Oilers (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202342,
    id: 2623005,
    name: "NHL Stanley Cup Finals: TBD at Edmonton Oilers (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202345,
    id: 2623006,
    name: "NHL Stanley Cup Finals: TBD at Edmonton Oilers (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202347,
    id: 2623007,
    name: "NHL Stanley Cup Finals: TBD at Edmonton Oilers (Home Game 4) (Date TBD) (If Necessary)",
  },
];

export const canucks2024TEId = [
  {
    playoffSequence: 202431,
    id: 2613201,
    name: "NHL Western Conference Quarterfinals: TBD at Vancouver Canucks (Home Game 1) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202432,
    id: 2613310,
    name: "NHL Western Conference Quarterfinals: TBD at Vancouver Canucks (Home Game 2) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202433,
    id: 2613408,
    name: "NHL Western Conference Quarterfinals: TBD at Vancouver Canucks (Home Game 3) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202434,
    id: 2613448,
    name: "NHL Western Conference Quarterfinals: TBD at Vancouver Canucks (Home Game 4) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202435,
    id: 2621085,
    name: "NHL Western Conference Semifinals: TBD at Vancouver Canucks (Home Game 1) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202436,
    id: 2621086,
    name: "NHL Western Conference Semifinals: TBD at Vancouver Canucks (Home Game 2) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202437,
    id: 2621087,
    name: "NHL Western Conference Semifinals: TBD at Vancouver Canucks (Home Game 3) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202438,
    id: 2621088,
    name: "NHL Western Conference Semifinals: TBD at Vancouver Canucks (Home Game 4) (Date TBD) (If Neccessary)",
  },
  {
    playoffSequence: 202331,
    id: 2622776,
    name: "NHL Western Conference Finals: TBD at Vancouver Canucks (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202332,
    id: 2622777,
    name: "NHL Western Conference Finals: TBD at Vancouver Canucks (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202335,
    id: 2622778,
    name: "NHL Western Conference Finals: TBD at Vancouver Canucks (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202337,
    id: 2622780,
    name: "NHL Western Conference Finals: TBD at Vancouver Canucks (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202341,
    id: 2622970,
    name: "NHL Stanley Cup Finals: TBD at Vancouver Canucks (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202342,
    id: 2622971,
    name: "NHL Stanley Cup Finals: TBD at Vancouver Canucks (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202345,
    id: 2622972,
    name: "NHL Stanley Cup Finals: TBD at Vancouver Canucks (Home Game 3) (Date TBD) (If Necessary)",
  },
];

export const leafs2024TEId = [
  {
    playoffSequence: 202441,
    id: 2612939,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202442,
    id: 2612957,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202443,
    id: 2612969,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202444,
    id: 2612981,
    name: "NHL Eastern Conference Quarterfinals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202445,
    id: 2613075,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 1) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202446,
    id: 2613012,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 2) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202447,
    id: 2613033,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 3) (Date TBD) (If Necessary)",
  },
  {
    playoffSequence: 202448,
    id: 2613052,
    name: "NHL Eastern Conference Semifinals: TBD at Toronto Maple Leafs (Home Game 4) (Date TBD) (If Necessary)",
  },
];

export const whitecaps2024TEId = [
  {
    playoffSequence: 202449,
    id: 2646936,
    name: "Canadian Championship - Cavalry FC at Vancouver Whitecaps FC",
  },
  {
    playoffSequence: 202450,
    id: 2619737,
    name: "Leagues Cup - Tijuana fc at Vancouver Whitecaps FC",
  },
  {
    playoffSequence: 202451,
    id: 2740669,
    name: "Toronto FC at Vancouver Whitecaps FC",
  },
];