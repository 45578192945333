import FLAMES_SECTIONS from '../constants/FlamesSeatSections';
import WRANGLERS_SECTIONS from '../constants/WranglersSeatSections';
import JETS_SECTIONS from '../constants/JetsSeatSections';
import OILERS_SECTIONS from '../constants/OilersSeatSections';
import CANUCKS_SECTIONS from '../constants/CanucksSeatSections';
import MAPLE_LEAFS_SECTIONS from '../constants/MapleLeafsSeatSections';
import SENATORS_SECTIONS from '../constants/SenatorsSeatSections';
import RAPTORS_SECTIONS from '../constants/RaptorsSeatSections';
import BLUE_JAYS_SECTIONS from '../constants/BlueJaysSeatSections';
import OILERS_HERITAGE_SECTIONS from '../constants/OilersHeritageSeatSections';
import ELKS_SECTIONS from "../constants/ElksSeatSections";
import BLUE_BOMBERS_SECTIONS from "../constants/BlueBombersSeatSections";
import ARGONAUTS_SECTIONS from "../constants/ArgonautsSeatSections";
import TORONTOFCS_SECTIONS from "../constants/TorontoFcsSeatSections";
import STAMPEDERS_SECTIONS from "../constants/StampedersSeatSections";
import REDBLACKS_SECTIONS from "../constants/RedblacksSeatsSections";
import LIONS_SECTIONS from "../constants/LionsSeatSections";
import WHITECAPS_SECTIONS from "../constants/WhitecapsSeatSections";
import ROUHGRIDERS_SECTIONS from "../constants/RoughridersSeatSections";
import TIGERCATS_SECTIONS from "../constants/TigerCatsSeatSections";
import LIONS_SPECIAL_SECTIONS from "../constants/LionsSpecialSeatSections";
import ABBOTSFORD_CANUCKS_SECTIONS from "../constants/AbbotsfordSeatSections";
import UFC_EDMONTON_SECTIONS from "../constants/UfcEdmontonSeatSections";

const getSectionsBySlug = (slug) => {
  switch (slug) {
    case 'calgary-flames': {
      return FLAMES_SECTIONS;
    }

    case 'calgary-wranglers': {
      return WRANGLERS_SECTIONS;
    }

    case 'winnipeg-jets':
    case 'manitoba-moose': {
      return JETS_SECTIONS;
    }

    case 'edmonton-oilers': {
      return OILERS_SECTIONS;
    }

    case 'vancouver-canucks': {
      return CANUCKS_SECTIONS;
    }

    case 'toronto-maple-leafs': {
      return MAPLE_LEAFS_SECTIONS
    }

    case 'ottawa-senators': {
      return SENATORS_SECTIONS
    }

    case 'toronto-raptors': {
      return RAPTORS_SECTIONS
    }

    case 'toronto-blue-jays': {
      return BLUE_JAYS_SECTIONS
    }

    case "edmonton-elks": {
      return ELKS_SECTIONS;
    }

    case "winnipeg-blue-bombers": {
      return BLUE_BOMBERS_SECTIONS;
    }

    case "toronto-argonauts": {
      return ARGONAUTS_SECTIONS;
    }
    
    case "calgary-stampeders": {
      return STAMPEDERS_SECTIONS;
    }
    
    case "ottawa-redblacks": {
      return REDBLACKS_SECTIONS;
    }
    
    case "grey-cup": 
    case "bc-lions": {
      return LIONS_SECTIONS;
    }

    case "saskatchewan-roughriders": {
      return ROUHGRIDERS_SECTIONS;
    }
    
    case "vancouver-whitecaps-fc": {
      return WHITECAPS_SECTIONS;
    }    
    
    case "toronto-fc": {
      return TORONTOFCS_SECTIONS;
    }
    
    case "hamilton-tigercats": {
      return TIGERCATS_SECTIONS;
    }

    case "abbotsford-canucks": {
      return ABBOTSFORD_CANUCKS_SECTIONS;
    }

    case "ufc-fight-night": {
      return UFC_EDMONTON_SECTIONS;
    }

    default: {
      return [{ label: 'Seat Section' }];
    }
  }
};

const getSpecialSectionsBySlug = (slug) => {
  switch (slug) {
    case 'edmonton-oilers': {
      return OILERS_HERITAGE_SECTIONS;
    }
    
    case 'toronto-raptors': {
      return CANUCKS_SECTIONS;
    }

    case 'vancouver-whitecaps-fc': {
      return LIONS_SECTIONS;
    }

    case 'bc-lions': {
      return LIONS_SPECIAL_SECTIONS;
    }

    case 'vancouver-canucks': {
      return ABBOTSFORD_CANUCKS_SECTIONS;
    }

    default: {
      return [{ label: 'Seat Section' }];
    }
  }
};

export {
  getSectionsBySlug,
  getSpecialSectionsBySlug
};
