import React, { Component } from "react";
import moment from "moment-timezone";
import querySearch from "stringquery";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import * as Sentry from "@sentry/browser";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import TextLogo from "../images/checkout-logo-ff.png";
import StubHubLogo from "../images/checkout-logo-stubhub.png";
import TicketMasterLogo from "../images/checkout-logo-ticketmaster.png";
import SeatGeekLogo from "../images/checkout-logo-seatgeek.png";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import GradeIcon from '@material-ui/icons/Grade';

import CheckoutForm from "../components/CheckoutForm";
import cdialog from "../components/cdialog";
import creditCardIcon from "../images/icons/credit-card.png";
import { nextDaysGameCheck, renderZoneName, renderRowName, testDataCheck } from "../libs/shared/seat-helpers";
import { getToken } from "../services/payment";
import Setting from "../constants/Setting";
import { getFormattedTime, getTimeAtLabels } from "../libs/shared/grid-helper";
import { randomIntFromInterval } from "../utils/randomNumber";
import { checkoutReviewsData } from "../components/data/checkoutReviews";
import { getThreeDSStatus } from "../services/setting";

const seatDetails = {
  id: 1,
  zone: 1,
  section: "Upper Zone 12",
  tickets: 2,
  row: 1,
  price: 50,
  game: "Canucks at Flames",
  date: new Date("10/06/2018 19:00"),
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  MuiAppBar: {
    boxShadow: "0 3px 20px 0 rgba(189, 189, 189, 0.5)",
    marginBottom: "1.5rem",
  },
  MuiTableRow: {
    height: "1.5rem",
  },
  MuiTableCell: {
    borderBottom: "none",
    padding: 0,
  },
  [theme.breakpoints.up("md")]: {
    checkoutDetails: {
      padding: "2rem",
    },
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
  },
  paperCard: {
    paddingTop: theme.spacing.unit * 2,
  },
  paperReviews: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: "8px",
    border: "solid 1px rgba(0, 0, 0, 0.54)",
    marginBottom: '12px',
    padding: '8px',
  },
  paperReviewsQuotes: {
    fontFamily: "cursive",
    fontSize: "56px",
    color: "rgba(0, 0, 0, 0.54)",
    padding: '12px',
    lineHeight: '0.5',
  },
});

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableSeats: 3,
      firstName: "",
      lastName: "",
      email: "",
      noOfSeats: 1,
      ffTotal: 0,
      stubHubTotal: 0,
      ticketMasterTotal: 0,
      ticketMasterService: 0,
      seatGeekTotal: 0,
      seatGeekService: 0,
      fulfillmentFee: 0,
      service: 0,
      usdConversion: 0,
      seat: null,
      game: null,
      isSubmitting: false,
      isSubmitted: false,
      isValidEmail: true,
      isValidName: true,
      isLoading: true,
      stubHubSubTotal: 0,
      ticketMasterSubTotal: 0,
      gameName: "",
      gameDate: "",
      showWarningDialog: false,
      failedTransactions: 0,
      showTicketInstructionsDialog: true,
      msgOpen: true,
      checkoutAlert: false,
      alertTitle: "",
      alertContent: "",
      refresh: false,
      refreshURL: "",
      paymentSdk: null,
      threeDSStatus: "on"
    };

    this.toggleCheckoutAlert = this.toggleCheckoutAlert.bind(this);
    this.onCouponApplied = this.onCouponApplied.bind(this);
    this.authenticatePayment = this.authenticatePayment.bind(this);
    this.pay = this.pay.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  hideInstaDeliveryText = () => {
    const currentTime = moment().tz("America/Edmonton").format("HH:mm");
    const startHideTime = "00:00"; // 12:00 am
    const endHideTime = "06:00"; // 06:00 am
  
    if (currentTime >= startHideTime && currentTime <= endHideTime) {
      return null;
    } else {
      return "  Insta-Delivery";
    }
  }

  tmPercFee(slug) {
    switch (slug) {
      case "calgary-flames":
      case "vancouver-canucks":
      case "toronto-maple-leafs":
      case "toronto-raptors":
      case "toronto-blue-jays":
      case "ottawa-senators":
      case "edmonton-oilers":
      case "edmonton-elks":
      case "winnipeg-blue-bombers":
      case "toronto-argonauts":
      case "calgary-stampeders":
      case "ottawa-redblacks": 
      case "calgary-wranglers":
      case "abbotsford-canucks":
      case "bc-lions": {
        return 0.22;
      }

      case "winnipeg-jets": {
        return 0.18;
      }
      default:
        return 0.22;
    }
  }
  calculateTotal(total, discount) {
    if (!discount) {
      return total;
    } else {
      return total - discount < 0 ? 0 : total - discount;
    }
  }

  async token() {
    try {
      const response = await getToken();
      const { token } = response;
      this.setState({
        paymentSdk: new window.Elavon3DSWebSDK({
          // baseUrl: "https://uat.gw.fraud.eu.elavonaws.com/3ds2",
          baseUrl: "https://gw.fraud.elavon.com/3ds2",
          token: token,
        }),
      });
    } catch (err) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "Error",
        alertContent: "There was a technical issue on our end with your transaction. We are trying to resolve the situation. Please try again in thirty minutes or email tix@fansfirst.ca for immediate assistance.",
        refresh: true,
        refreshURL: `/`,
      });
    }
  }

  async componentDidMount() {
    window.fbq("track", "InitiateCheckout");
    const { teId, teListingId } = querySearch(
      this.props.location.search
    );
    const isLoadTE = teId && teId !== "";
    const teQuery = isLoadTE
      ? `teId=${teId}&teListingId=${teListingId}`
      : "";

    const seatDetails = await this.seat();
    const remainingTickets = seatDetails.noOfSeats - seatDetails.seatsSold;
    const seat = { ...seatDetails, ...{ noOfSeats: remainingTickets } };

    const failedTransactions = parseInt(localStorage.getItem("tft"));
    const nextCheckoutDateTime = parseInt(localStorage.getItem("ncdt"));

    this.setState({
      failedTransactions: failedTransactions ? failedTransactions : 0,
    });
    const game = await this.game();
    if (
      moment.now() > moment(game.date).add(1, "hour").valueOf() &&
      !game.isTbd
    ) {
      alert("Sorry, this event is no longer available.");
      this.props.history.push("/");
    }
    if (testDataCheck(game)) {
      alert("Sorry, this event is no longer available.");
      this.props.history.push("/");
    }

    if (
      failedTransactions >= 3 &&
      !moment().isAfter(nextCheckoutDateTime, "second")
    ) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "Too Many Failed Transactions",
        alertContent:
          "You have too many failed transactions. Please try again later or call/text 1-403-768-2298 and we will help you get the seats you want.",
        refresh: true,
        refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
      });
      /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
    } else if (
      failedTransactions >= 3 &&
      moment().isAfter(nextCheckoutDateTime, "second")
    ) {
      localStorage.removeItem("tft");
      localStorage.removeItem("ncdt");
    }

    if (seat.noOfSeats === 0 || seat.isArchived) {
      /* alert("This seat is not available anymore."); */
      this.setState({
        checkoutAlert: true,
        alertTitle: "Seat Not Available",
        alertContent: "This seat is not available anymore.",
        refresh: true,
        refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
      });
      /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
    }

    const { seats } = querySearch(this.props.location.search);

    const ffTotal = this.calculateTotal(
      seats * seat.price,
      this.state.coupon ? this.state.coupon.value : null
    );

    const stubHubSubTotal = ffTotal;
    const ticketMasterSubTotal = ffTotal;

    const fulfillmentFee = seats * 2.5;
    const service = ffTotal * 0.37;
    const usdConversion = ffTotal * 0.025;
    const stubHubTotal = stubHubSubTotal + service;

    const ticketMasterService = ffTotal * this.tmPercFee(game.homeTeamSlug);
    const ticketMasterTotal = ticketMasterSubTotal + ticketMasterService;

    const seatGeekService = ffTotal * 0.35;
    const seatGeekTotal = ffTotal + seatGeekService;

    const regularGameDate = `${moment
      .tz(game.date, game.timezone)
      .format("ddd, MMM D YYYY")}${getTimeAtLabels(game)}${getFormattedTime(moment
      .tz(game.date, game.timezone)
      .format("h:mm A"), game)}`;

    const gameDate = game.isTbd ? "TBD" : regularGameDate;

    const threeDSStatus = await getThreeDSStatus();

    // API Call
    this.setState({
      noOfSeats: seats,
      ffTotal,
      fulfillmentFee,
      service,
      usdConversion,
      stubHubTotal,
      ticketMasterTotal,
      ticketMasterService,
      seatGeekTotal,
      seatGeekService,
      game,
      seat,
      isLoading: false,
      stubHubSubTotal,
      ticketMasterSubTotal,
      gameName: game.longName,
      gameDate,
      showWarningDialog: game.isTBA ? game.isTBA : false,
      teamName: game.homeTeam,
      threeDSStatus
    });
  }

  ticketMasterSubTotal(subTotal, qty, { zone, price }) {
    switch (zone) {
      case "Upper Zone": {
        return price > 25 ? subTotal : 25 * qty;
      }
      case "Middle Zone": {
        return price > 44 ? subTotal : 44 * qty;
      }
      case "Lower Zone": {
        return price > 142 ? subTotal : 142 * qty;
      }
      default: {
        return price * qty;
      }
    }
  }

  _getTEDetails = async (details) => {
    const {game} = this.state;
    const { teListingId, seats: noOfSeats } = querySearch(
      this.props.location.search
    );
    // this.setState({ isLoading: true });
    const isNexDaysValid = nextDaysGameCheck(game);
    if (!isNexDaysValid) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "No Longer Available",
        alertContent:
          "Sorry, those tickets are no longer available or someone else got them.",
        refresh: true,
        refreshURL: `/`,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
      return;
    }
    try {
      const teDetails = await API.get(
        "v2",
        `marketplace/ticketEvo/listings/show?listingId=${teListingId}`
      );
      if (
        teDetails &&
        teDetails.available_quantity &&
        teDetails.available_quantity >= noOfSeats
      ) {
        this.pay(details);
      } else {
        this.setState({
          checkoutAlert: true,
          alertTitle: "No Longer Available",
          alertContent:
            "Sorry, those tickets are no longer available or someone else got them.",
          refresh: true,
          refreshURL: `/`,
        });
        this.setState({ isLoading: false, checkoutSuccess: false });
      }
    } catch (error) {
      this.setState({
        checkoutAlert: true,
        alertTitle: "No Longer Available",
        alertContent:
          "Sorry, those tickets are no longer available or someone else got them.",
        refresh: true,
        refreshURL: `/`,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
    }
  };

  notifyFailedTransaction(details) {
    const { ffTotal, game, seat, gameName, gameDate, noOfSeats } = this.state;
    const section = renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
    const row = renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial);
    const description = `${noOfSeats} ticket(s): ${gameName} - ${gameDate}, ${section.trim()} Row ${row.trim()}`;

    API.post("v2", "marketplace/orders/failed-transaction-notification", {
      body: {
        seat: seat.seatId,
        amount: Number(ffTotal.toFixed(2)),
        email: details.email,
        mobileNo: "+1" + details.mobileNo.replace(/\D/g, ""),
        name: `${details.firstName} ${details.lastName}`,
        description,
        card: details.card,
        error_code: details.error_code,
        reason: "3DS Authentication Failed",
      },
    });
  }

  handleCheckout(details) {
    this.setState({ isLoading: true });
    if (this.state.threeDSStatus === "on") {
      this.authenticatePayment(details);
    } else {
      const orderDetail = {
        ...details,
        threeDSecureValue: null,
        threeDSMessageVersion: null,
        threeDSServerTransID: null,
        threeDSTransStatus: null,
        dsTransID: null,
        eci: null,
        messageType: null,
      };
      const { teId } = querySearch(this.props.location.search);
      const isLoadTE = teId && teId !== "";
      if (isLoadTE) {
        this._getTEDetails(orderDetail);
      } else {
        this.pay(orderDetail);
      }
    }
  }

  async authenticatePayment(details) {
    try {
      await this.token();
      // this.setState({ isLoading: true });
      const { paymentSdk, ffTotal } = this.state;
      const { teId } = querySearch(this.props.location.search);
      const isLoadTE = teId && teId !== "";

      const purchaseAmount = Number(ffTotal.toFixed(2));
      const authRequest = {
        acctNumber: details.card,
        cardExpiryDate: moment(details.expiryDate, "MMYY").format("YYMM"),
        purchaseAmount: parseInt(purchaseAmount * 100),
        purchaseCurrency: Setting.ELAVON_PURCHASE_CURRENCY,
        purchaseExponent: Setting.ELAVON_PURCHASE_EXPONENT,
        messageCategory: Setting.ELAVON_MESSAGE_CATEGORY,
        transType: Setting.ELAVON_TRANS_TYPE,
        threeDSRequestorAuthenticationInd:
          Setting.ELAVON_3DS_REQUEST_OR_AUTHENTICATION_IND,
        threeDSRequestorChallengeInd: Setting.ELAVON_3DS_REQUESTOR_CHALLENGE_IND,
        clientStartProtocolVersion: Setting.ELAVON_3DS_CLIENT_START_PROTOCOL_VERSION,
        clientEndProtocolVersion: Setting.ELAVON_3DS_CLIENT_END_PROTOCOL_VERSION,
        cardholderName: `${details.firstName} ${details.lastName}`,
        mobilePhone: {
          cc: "1",
          subscriber: details.mobileNo.replace(/\D/g, ""),
        },
        billAddrPostCode: details.zipCode,
        merchantRiskIndicator: {
          deliveryEmailAddress: details.email,
        },
      };

      paymentSdk
        .web3dsFlow(authRequest)
        .then((response) => {
          var allowedTransStatus = ['Y', 'I', 'A'];
          if ( (allowedTransStatus.indexOf(response.transStatus) !== -1) && (response.authenticationValue) && (response.eci) ) {
            const orderDetail = {
              ...details,
              threeDSecureValue: response.authenticationValue,
              threeDSMessageVersion: response.messageVersion,
              threeDSServerTransID: response.threeDSServerTransID,
              threeDSTransStatus: response.transStatus,
              dsTransID: response.dsTransID,
              eci: response.eci,
              messageType: response.messageType,
            };

            if (isLoadTE) {
              this._getTEDetails(orderDetail);
            } else {
              this.pay(orderDetail);
            }
          } else {
            this.setState({ isLoading: false });
            cdialog.error("Error", "Sorry we're experiencing a technical issue. Please email tix@fansfirst.ca or text 1-403-768-2298 for assistance.");
            this.notifyFailedTransaction({
              ...details,
              error_code: `${'transStatusReason' in response ? `${response.transStatusReason} - ` : '' }${response.message}, transStatus = ${response.transStatus}`
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          cdialog.error("Error", "The card authentication failed.");
        });
      } catch(err) {
        this.setState({
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent: "There was a technical issue on our end with your transaction. We are trying to resolve the situation. Please try again in thirty minutes or email tix@fansfirst.ca for immediate assistance.",
          refresh: true,
          refreshURL: `/`,
        });
      }
  }

  async pay(details) {
    const { ffTotal, game, seat, noOfSeats, gameName, gameDate, coupon } =
      this.state;
    const { teId, teListingId } = querySearch(
      this.props.location.search
    );
    const isLoadTE = teId && teId !== "";
    const teQuery = isLoadTE
      ? `teId=${teId}&teListingId=${teListingId}`
      : "";

    this.setState({ isLoading: true });

    const section = renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial);
    const row = renderRowName(seat.zone, seat.row, game.homeTeamSlug, game.isSpecial);
    const description = `${noOfSeats} ticket(s): ${gameName} - ${gameDate}, ${section.trim()} Row ${row.trim()}`;

    var mobileNo = details.mobileNo;
    mobileNo = mobileNo.replace(/\D/g, "");

    if (mobileNo.match(/^\d{10}$/)) {
      mobileNo = "+1" + mobileNo;
    } else if (mobileNo.match(/^\+\d{11}$/)) {
      mobileNo = mobileNo = "+" + mobileNo;
    } else {
      this.setState({
        checkoutAlert: true,
        alertTitle: "Invalid Phone Number",
        alertContent:
          "Please enter a valid Candian mobile phone number (no VOIP or landlines).",
        refresh: false,
      });
      this.setState({ isLoading: false, checkoutSuccess: false });
      /* window.location.reload(false); */

      return;
    }

    try {
      const game = await this.game();
      if (
        moment.now() > moment(game.date).add(1, "hour").valueOf() &&
        !game.isTbd
      ) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      if (testDataCheck(game)) {
        alert("Sorry, this event is no longer available.");
        this.props.history.push("/");
      }
      const { order, show_popup } = await API.post(
        "v2",
        "marketplace/orders/checkout",
        {
          body: {
            amount: Number(ffTotal.toFixed(2)),
            email: details.email,
            mobileNo: mobileNo,
            name: details.firstName + " " + details.lastName,
            description,
            noOfSeats,
            ticketPrice: seat.price,
            game: seat.game,
            seat: seat.seatId,
            card: details.card,
            expiryDate: details.expiryDate,
            cvv: details.cvv,
            postalCode: details.zipCode,
            couponId: coupon ? coupon.id : null,
            threeDSecureValue: details.threeDSecureValue,
            threeDSMessageVersion: details.threeDSMessageVersion,
            threeDSServerTransID: details.threeDSServerTransID,
            threeDSTransStatus: details.threeDSTransStatus,
            dsTransID: details.dsTransID,
            eci: details.eci,
            messageType: details.messageType,
            teListingId: isLoadTE ? teListingId : null,
          },
        }
      );

      if (show_popup) {
        cdialog.info(
          "Follow-up Identification",
          <>
            Thanks for using FansFirst! Due to our ongoing security measures, we
            may have to verify that you are the physical credit card holder. If
            that's the case, you <b>may</b> receive a text from{" "}
            <b>403.768.2298</b> asking for a photo of the card, emphasizing you
            block out <b>EVERYTHING</b> except the name and last four digits
            (three if AMEX). We already know these details, so the picture
            proves you physically hold the card.
          </>,
          {
            html: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={creditCardIcon}
                  alt="Credit Card Icon"
                  style={{ maxWidth: "200px", marginTop: "10px" }}
                />
              </div>
            ),
          }
        );
      }

      // Workaround, We are facing weird issue, order created but user still got unexpected error message
      // when user have addblocker this function will lead to exception error
      // window.fbq("track", "Purchase", {
      //   value: order.ticketPrice * order.noOfSeats,
      //   currency: "CAD",
      // });

      this.setState({ checkoutSuccess: true });
      localStorage.removeItem("teSeatSplit");
      this.props.history.push(`/thankyou/${order.id}`);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        Sentry.captureMessage(error);
        Sentry.captureException(error);

        if (data.cardError) {
          const { failedTransactions } = this.state;

          const newFailedTxnsTotal = failedTransactions + 1;

          this.setState({
            failedTransactions: newFailedTxnsTotal,
          });

          localStorage.setItem("tft", JSON.stringify(newFailedTxnsTotal));

          if (failedTransactions === 2) {
            localStorage.setItem("ncdt", moment().add(1, "hours").valueOf());

            /* alert(
            ); */
            this.setState({
              checkoutAlert: true,
              alertTitle: "Too many failed transactions",
              alertContent:
                "You have too many failed transactions. Please try again later or call/text 1-403-768-2298 and we will help you get the seats you want via Interac e-transfer.",
              refresh: true,
              refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
            });

            /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
            return;
          }
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: false,
          });
        } else if (data.invalidNumber) {
          /* alert("Please enter a valid phone number"); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Invalid Phone Number",
            alertContent:
              "Please enter a valid Candian mobile phone number (no VOIP or landlines).",
            refresh: false,
          });
          this.setState({ isLoading: false, checkoutSuccess: false });
          /* window.location.reload(false); */
          return;
        } else if (!data.isSeatAvailable) {
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: true,
            refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
          });
          /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
        } else if (data.isVPN) {
          /* alert(data.message); */
          this.setState({
            checkoutAlert: true,
            alertTitle: "Error",
            alertContent: data.message,
            refresh: true,
            refreshURL: `/games/${game.id}/seats?zone=0&${teQuery}`,
          });
          /* this.props.history.push(`/games/${game.id}/seats?zone=0`); */
        }
      } else {
        /* alert(
        ); */
        this.setState({
          checkoutAlert: true,
          alertTitle: "Error",
          alertContent:
            "We are having troubles processing your order. Please call or text 1-403-768-2298 and we will help you get the seats you want.",
          refresh: false,
        });
      }

      this.setState({ isLoading: false, checkoutSuccess: false });
    }
  }

  seat() {
    const { game, seat } = querySearch(this.props.location.search);
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${seat}`
    );
  }

  game = () => {
    const { game } = querySearch(this.props.location.search);
    return API.get("v2", `marketplace/events/by/eventId/${game}`);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });

    if (name === "noOfSeats") {
      const ffTotal = this.calculateTotal(
        event.target.value * seatDetails.price,
        this.state.coupon.value
      );
      const othersTotal = ffTotal + 3.25 + 21.45 + 3.06;

      this.setState({
        ffTotal,
        othersTotal,
      });
    }
  };

  toggleCheckoutAlert() {
    const { refresh, refreshURL } = this.state;
    this.setState({ checkoutAlert: !this.state.checkoutAlert });
    if (refresh) {
      this.props.history.push(refreshURL);
    }
  }

  renderSeatDetails = () => {
    const { game, noOfSeats, seat, gameName, gameDate } = this.state;

    if (game) {
      return (
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Typography variant="body2" color="inherit">
            {gameName}
          </Typography>
          <Typography variant="caption" color="inherit">
            {game.isSpecial && (
              <React.Fragment>{game.venue}</React.Fragment>
            )}
            {gameDate}
          </Typography>
          <Typography variant="caption" color="inherit">
            {noOfSeats} Ticket{noOfSeats > 1 ? "s" : ""} (${seat.price} ea) •{" "}
            {renderZoneName(seat.zone, seat.zoneNo, seat.row, game.homeTeamSlug, game.isSpecial)} • Row{" "}
            {seat.row}{" "}
            <Typography
              style={{
                display: "inline-block",
                color: "rgb(33, 150, 243)",
                fontStyle: "oblique",
              }}
              variant="caption"
            >
              {seat.isAisleSeat && parseInt(seat.seatsSold) === 0
                ? "  Aisle Seats"
                : null}
            </Typography>
            {seat.isAisleSeat &&
            parseInt(seat.seatsSold) === 0 &&
            seat.isInstantDelivery ? (
              <Typography
                style={{
                  display: "inline-block",
                  color: "#e03a3e",
                  fontStyle: "oblique",
                }}
                variant="caption"
              >
                &nbsp;•&nbsp;
              </Typography>
            ) : null}
            <Typography
              style={{
                display: "inline-block",
                color: "#e03a3e",
                fontStyle: "oblique",
              }}
              variant="caption"
            >
              {seat.isInstantDelivery ? this.hideInstaDeliveryText() : null}
            </Typography>
          </Typography>
          {seat.isWheelchair ? (
            <Typography
              style={{
                display: "inline-block",
                color: "rgb(33, 150, 243)",
                fontStyle: "oblique",
              }}
              variant="caption"
            >
              Wheelchair
            </Typography>
          ) : null}
          {seat.isObstructedView ? (
            <Typography
              style={{
                display: "inline-block",
                color: "rgb(33, 150, 243)",
                fontStyle: "oblique",
              }}
              variant="caption"
            >
              Obstructed View
            </Typography>
          ) : null}
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Typography variant="body2" color="inherit">
            Loading Seat Details...
          </Typography>
        </div>
      );
    }
  };

  renderCheckoutDetails = () => {
    const {
      ffTotal,
      stubHubTotal,
      ticketMasterService,
      ticketMasterTotal,
      seatGeekTotal,
      seatGeekService,
      service,
      game,
    } = this.state;
    // const { classes } = this.props;

    return (
      <Grid container spacing={8}>
        <Grid item xs={3} />
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          <Typography variant="body2" color="secondary" align="right">
            <img src={TextLogo} alt="FansFirst" width="100%" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          <Typography variant="body2" color="secondary" align="right">
            <img src={StubHubLogo} alt="StubHub" width="100%" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ borderBottom: "1px solid #e1e1e1", marginBottom: ".5rem" }}
        >
          <Typography variant="body2" color="secondary" align="right">
            {game.homeTeamSlug === "toronto-blue-jays" ? (
              <img src={SeatGeekLogo} alt="SeatGeek" width="100%" />
            ) : (
              <img src={TicketMasterLogo} alt="TicketMaster" width="100%" />
            )}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            Buyer Fee
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            align="right"
          >
            -
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${service.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? seatGeekService.toFixed(2)
              : ticketMasterService.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2">Total</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="body2"
            align="right"
            style={{
              color: "#2196f3",
              paddingRight: "16px",
            }}
          >
            ${ffTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${stubHubTotal.toFixed(2)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? seatGeekTotal.toFixed(2)
              : ticketMasterTotal.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={3} />
        <Grid item xs={3}>
          <Typography variant="body2">You saved:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "14px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            ${(stubHubTotal - ffTotal).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{ paddingRight: "16px" }}
            variant="body2"
            color="secondary"
            align="right"
          >
            $
            {game.homeTeamSlug === "toronto-blue-jays"
              ? (seatGeekTotal - ffTotal).toFixed(2)
              : (ticketMasterTotal - ffTotal).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} style={{ marginTop: ".5rem" }}>
          <Typography
            style={{ fontSize: "10px" }}
            variant="caption"
            align="right"
          >
            * Minimum ticket prices and fees for secondary market resale tickets
            on competing sites. Competitior prices are calculations based on
            respective fee structures currently in place.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderWarningDialog() {
    const { showWarningDialog, teamName } = this.state;

    return (
      <Dialog
        open={showWarningDialog}
        onClose={() => this.setState({ showWarningDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You Are Buying {teamName} Playoffs Tickets
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: ".875rem" }}
          >
            The date and time are all subject to change and will be
            updated as soon as the official schedule is release by the league.
            <br />
            <br />
            If the game you have purchased doesn't occur (the series ends early
            or the {teamName} don't make it to a particular round of the playoffs)
            then your transaction is cancelled and you get a full refund.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ showWarningDialog: false })}
            color="primary"
            autoFocus
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDialog() {
    return (
      <Dialog
        open={this.state.msgOpen}
        onClose={() => this.setState({ msgOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Season Postponed</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Hi,
            <br />
            <br />
            Thanks again for using FansFirst. Your business and your trust in us
            means more than you know. Today the NHL postponsed the season. We
            don't know what this will mean yet as far as rescheduling vs
            cancellations. As soon as we know more we will let you know. Right
            now we need to focus on looking after our families, hockey will have
            to wait.
            <br />
            <br />
            As soon as games are officially cancelled we will begin processing
            refunds. As soon as we know more we will send additional updates.
            <br />
            <br />
            If you sold tickets on FansFirst we will contact you as soon as we
            have any additional information. We're doing our best. This is a
            crazy situation. Take care.
            <br />
            <br />
            All ticket holders should keep their existing tickets for games that
            have been suspended and they will be valid for the rescheduled
            dates.
            <br />
            <br />
            <br />
            <strong>The FansFirst Team</strong>
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
  renderTicketInstructions() {
    // const { showTicketInstructionsDialog } = this.state;

    // return (
    //   <Dialog
    //     open={showTicketInstructionsDialog}
    //     onClose={() => this.setState({ showTicketInstructionsDialog: false })}
    //     aria-labelledby='alert-dialog-title'
    //     aria-describedby='alert-dialog-description'
    //   >
    //     <DialogTitle id='alert-dialog-title'>
    //       Important Instructions
    //     </DialogTitle>
    //     <DialogContent>
    //       <DialogContentText
    //         id='alert-dialog-description'
    //         style={{ fontSize: '.875rem' }}
    //       >
    //         We guarantee all digitally scanned tickets. Printed copies do not
    //         fall under the guarantee. We encourage you to <strong>NOT</strong>{' '}
    //         print your tickets.
    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button
    //         onClick={() =>
    //           this.setState({ showTicketInstructionsDialog: false })
    //         }
    //         color='primary'
    //         autoFocus
    //       >
    //         I understand
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
    // );

    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={1}>
        <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          Important Instructions
        </Typography>

        <Typography variant="body1">
          We guarantee all digitally scanned tickets. Printed copies do not fall
          under the guarantee. We encourage you to <strong>NOT</strong> print
          your tickets.
        </Typography>
      </Paper>
    );
  }
  onCouponApplied = (coupon) => {
    this.setState({ coupon });
  };
  renderCheckoutAlert() {
    const { checkoutAlert, alertTitle, alertContent } = this.state;
    return (
      <Dialog open={checkoutAlert} onClose={this.toggleCheckoutAlert}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertContent}</DialogContent>
        <DialogActions>
          <Button onClick={this.toggleCheckoutAlert} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderReviews() {
    const { classes } = this.props;
    const randomArray = randomIntFromInterval(checkoutReviewsData,2);
    const currentReview1 = randomArray[0] || {
      "name": "",
      "profile_pic": "",
      "comments": ""
    };
    const currentReview2 = randomArray[1] || {
      "name": "",
      "profile_pic": "",
      "comments": ""
    };

    return (
      <div className={classes.paperCard}>
      <Typography
          variant="caption"
          style={{
            textTransform: "uppercase",
            marginBottom: ".5rem",
          }}
        >
          REVIEWS
      </Typography>
        <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview1.name}
            </Typography>

            <Typography variant="body1">{currentReview1.comments}</Typography>
          </Grid>
        </div>

        {currentReview2 !== currentReview1 && <div className={classes.paperReviews}>
          <Grid>
            <Typography
              variant="caption"
              style={{
                textTransform: "capitalize",
                marginBottom: ".5rem",
              }}
            >
              <GradeIcon style={{color: "rgb(34, 150, 243)", fontSize: "32px", marginBottom: "-12px"}} />
              <b>5.0 &nbsp;</b>
              {currentReview2.name}
            </Typography>

            <Typography variant="body1">{currentReview2.comments}</Typography>
          </Grid>
        </div>}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { seat, game } = this.state;
    const { teId } = querySearch(this.props.location.search);
    const isLoadTE = teId && teId !== "";
    const currentURL = window.location.href;

    return seat && game ? (
      <main
        style={{
          paddingBottom: "4rem",
          paddingTop: "1.5rem",
          position: "relative",
        }}
      >
        <Helmet>
          <title>Checkout</title>
          <meta name="description" content="Checkout" />
          <meta name="keywords" content={`checkout, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Checkout" />
          <meta property="og:description" content="Checkout" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Grid container>
          <Grid item md={2} xl={3} />
          <Grid item md={5} xl={4}>
            <Typography
              variant="headline"
              style={{
                fontWeight: "bold",
                color: "#2196f3",
              }}
            >
              Checkout
            </Typography>
            <CheckoutForm
              isLoading={this.state.isLoading}
              amount={this.state.ffTotal}
              onSubmit={this.handleCheckout}
              onCouponApplied={this.onCouponApplied}
              isAuthenticated={this.props.isAuthenticated}
              currUser={this.props.currUser}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            {this.renderTicketInstructions()}
            <div className={classes.checkoutDetails}>
              <Typography
                variant="caption"
                color="primary"
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Seat Details
              </Typography>

              {this.renderSeatDetails()}

              {this.renderCheckoutDetails()}
              {this._renderReviews()}
            </div>
          </Grid>
          {this.renderWarningDialog()}
          {this.renderCheckoutAlert()}
          {/* {this.renderDialog()} */}
        </Grid>
      </main>
    ) : (
      <div>
        <Typography variant="display1" gutterBottom align="center">
          Loading...
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Checkout);
