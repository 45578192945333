import TEAMS from "../../constants/Teams"

export const getTeamName = (homeTeamSlug) => {
  const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
  if (team) {
    return team.name;
  }

  return null;
}

export const getTeamColor = (homeTeamSlug) => {
  const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
  if (team) {
    return team.colors;
  }

  return null;
}

export const getGameName = (game) => {
  const slugs = ["ufc-fight-night"];
  if (game.isTbd || game.isPlayoffs || slugs.includes(game.homeTeamSlug)) {
    return game.longName;
  }

  const team1 = game.opponent;
  const team2 = getTeamName(game.homeTeamSlug);

  return `${team1} vs. ${team2 ? team2 : '-'}`;
}
