import React, { Component } from "react";
import { API } from "aws-amplify";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { 
  Card, 
  CardContent, 
  TextField, 
  InputAdornment,
  Button, 
  Divider, 
  Grow, 
  Grid, 
  Typography,
  withStyles,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import TrackOrdersTable from "../../components/TrackOrdersTable";

const styles = (theme) => ({
  root: {
    minWidth: 275,
    marginBottom: "1rem",
  },
  titleText: {
    color: '#2196f3',
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: "1.5rem",
    '& a': {
      color: "#2196f3",
      textDecoration: "none",
    },
    '& a:hover': { 
      color: "#1769aa",
    },
  },
  descriptionText: {
    fontSize: "0.85rem",
  },
  divider: {
    margin: ".5rem 0",
  },
  textField: {
    padding: ".5rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  textFieldError: {
    padding: ".5rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginBottom: ".25rem",
    fontSize: "0.875rem",
  },
  input: {
    padding: 0,
    fontSize: "0.875rem",
  },
  searchButton: {
    marginLeft: theme.spacing.unit,
  },
  searchIcon: {
    color: "#2196f3",
    fontSize: "1.2rem",
  },
  error: {
    color: "red",
    fontSize: "0.85rem",
  },
  noresult: {
    fontSize: "1.2rem", 
    fontWeight: 600,
     marginTop: "0.5rem",
     marginBottom: "0.5rem",
  },
  noresultText: {
    fontSize: "0.9rem", 
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    searchButton: {
      padding: "8px 12px"
    },
  }
});

class TrackOrderWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      statusOrders: null,
      isLoading: false,
      showResult: false,
      orderNumber: '',
      error: '',
    };
    this.handleSearch = this.handleSearch.bind(this);
    this._isMounted = false;
  }

  componentWillMount() {
    this._isMounted = true;
    const { statusOrders, isLoading, showResult } = this.props;
    if (this._isMounted) {
      this.setState({
        statusOrders,
        isLoading,
        showResult
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.statusOrders !== this.props.statusOrders) {
      const { statusOrders, isLoading, showResult } = nextProps;
      if (this._isMounted) {
        this.setState({
          statusOrders,
          isLoading,
          showResult
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleSearch = async () => {
    const { currUser } = this.props;
    const { orderNumber } = this.state;
  
    if (!orderNumber) {
      this.setState({ error: 'Please enter an order number' });
      return;
    }
  
    this.setState({ error: '', isLoading: true, showResult: false });
  
    try {
      const order = await this.getTrackingOrders(orderNumber.trim().toUpperCase());
      if (order) {
        const buyerEmail = currUser.email.toLowerCase().replace(/\s+/g, '');
        const filteredOrders = order.orders.filter(order => order.email.toLowerCase().replace(/\s+/g, '') === buyerEmail);
        const sortedOrders = _.orderBy(filteredOrders, ["createdAt"], ["desc"]);
  
        this.setState({
          statusOrders: sortedOrders,
          isLoading: false,
          showResult: true,
        });
      } else {
        this.setState({ statusOrders: [], isLoading: false, showResult: true });
      }
    } catch (error) {
      this.setState({ error: 'Failed to retrieve order status', isLoading: false });
    }
  };
  
  getTrackingOrders = async (orderNumber) => {
    const { isAuthenticated, currUser } = this.props;
    const params = [];
  
    if (isAuthenticated) {
      params.push(`buyerEmail=${currUser.email.toLowerCase().replace(/\s+/g, '')}`);
    }
    params.push(`orderNumber=${orderNumber}`);
  
    try {
      const response = await API.get("v2", `marketplace/orders/search?${params.join("&")}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  renderMain() {
    const { classes } = this.props;
    const {
      isLoading,
      statusOrders,
      orderNumber,
      error,
      showResult,
    } = this.state;

    return (
      <main>
        <Card className={classes.root}>
          <CardContent>
            <Typography 
              component="h2" 
              className={classes.titleText}
            >
              Track Your Tickets
            </Typography>
            <Divider className={classes.divider}/>
            <Typography className={classes.descriptionText}>
              Enter your Order Number to track the status of your order. You can find the Order Number in the Email Confirmation.
            </Typography>
            <TextField
              id="order-number"
              placeholder="Order Number"
              className={
                !error
                  ? classes.textField
                  : classes.textFieldError
              }
              value={orderNumber}
              onChange={(e) => this.setState({orderNumber: e.target.value})}
              margin="dense"
              disabled={isLoading}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={this.handleSearch}
              className={classes.searchButton}
              disabled={isLoading}
            >
              Search
            </Button>
            {error && (
              <Typography color="error" className={classes.error}>{error}</Typography>
            )}
          </CardContent>
        </Card>
        {isLoading ? (
          <React.Fragment>
            <Typography
                align="center"
                variant="body2"
                style={{ marginTop: "2rem" }}
            >
              <CircularProgress
              color="inherit"
              style={{ width: 16, height: 16, marginRight: 16 }}
              />
              Loading ticket status...
            </Typography>
          </React.Fragment>
        ) : (
          <Grow in={showResult}>
            <Card>
              <CardContent>
                <Typography 
                  component="h2" 
                  className={classes.titleText}
                >
                  <a href="/orders">Orders</a>
                </Typography>
                <Divider className={classes.divider}/>
                {!statusOrders || statusOrders.length === 0 ? (
                  <>
                    <Typography
                      variant="title"
                      className={classes.noresult}
                    >
                      No result found
                    </Typography>
                    <Divider/>
                    <Typography
                      variant="body2"
                      className={classes.noresultText}
                    >
                      We couldn't find a match for the order number you provided. <br />
                      Please verify your details and try again.
                    </Typography>
                  </>
                ) : (
                  <TrackOrdersTable
                    orders={statusOrders ? statusOrders : null}
                  />
                )}
              </CardContent>
            </Card>
          </Grow>
        )}
      </main>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            {this.renderMain()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TrackOrderWidget);